<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <h3 class="headings">Settings</h3>
  </div>
</div>
<div class="sett-bdy">
  <div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
    <div class="b_shadow">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 heading-card left-heading">
            <span class="pl-4">Enable Swipe/EMV tokenization</span>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
      <mat-slide-toggle [(ngModel)]="settings.tokenize" [disabled]="loadFlag" [color]="'primary'"
      (ngModelChange)="setTokenize()">Tokenize
      </mat-slide-toggle>
    </div>
  </div>
  </div>
</div>
<app-kount></app-kount>

<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
    <div class="b_shadow">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
        <span class="pl-sm-4 pl--0">Enable Metadata columns to Transaction list</span>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
        <mat-slide-toggle [(ngModel)]="settings.display_transactional_metadata" [disabled]="loadFlag" [color]="'primary'"
                          (ngModelChange)="setMetadata()">Add Metadata
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
      <div class="b_shadow">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
          <span class="pl-sm-4 pl--0">Disable Transaction Notifications Sent To Customer (Email)</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
          <mat-slide-toggle [(ngModel)]="settings.disable_charge_notification_email" [disabled]="loadFlag" [color]="'primary'"
                            (ngModelChange)="setChargeEmailNotification()"> On
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
      <div class="b_shadow">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
          <span class="pl-sm-4 pl--0">Disable Transaction Notifications Sent To Customer (SMS)</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
          <mat-slide-toggle [(ngModel)]="settings.disable_charge_notification_sms" [disabled]="loadFlag" [color]="'primary'"
                            (ngModelChange)="setChargeSmsNotification()"> On
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showPaypal">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
      <div class="b_shadow">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
          <span class="pl-sm-4 pl--0">Paypal Settings</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
          <div class="button pl-m-0"
               *ngIf="!dataManager['paypalStatus'] || (dataManager['paypalStatus']['internal_status'] !== 'boarded' &&
               dataManager['paypalStatus']['external_status'] !== 'ACTIVE'); else caseBoardedBlock">
            <button *ngIf="!loadFlag" id="enablePaypal" (click)="enablePaypal()" class="update-btn">
              Connect with <img [src]="paypalLogoUrl" alt="PayPal Logo" class="paypal-logo"/>
            </button>
            <div class="loader loader--style8" title="loading" *ngIf="loadFlag">
              <img src="../../../assets/images/uploading.svg">
            </div>
          </div>
          <ng-template #caseBoardedBlock>
            <span id="paypal-email-container" class="pl-4" style="display: flex; align-items: center;">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#0070BA" viewBox="0 0 16 16"
                   style="margin-right: 8px;" *ngIf="dataManager['paypalStatus'] &&
                    dataManager['paypalStatus']['internal_status'] === 'boarded'">
                <circle cx="8" cy="8" r="7" stroke="#0070BA" stroke-width="2" fill="white"/>
                <path fill="#0070BA" d="M6.75 8.75L5.5 7.5l-.75.75 2 2 4-4-.75-.75-3.25 3.25z"/>
              </svg>
              Your PayPal account is connected: {{ dataManager['paypalStatus']['email'] }}
              <span class="link-text" (click)="enablePaypal(true)">(Unlink this profile and link new one)</span>
            </span>
          </ng-template>
          <span class="d-block mx-w-35 validation-err-paypal" *ngIf="dataManager['paypalStatus'] &&
          dataManager['paypalStatus']['primary_email_confirmed'] === false">
            Attention: Please confirm your email address on
            <a href="https://www.paypal.com/businessprofile/settings" target="_blank" rel="noopener noreferrer"
               style="color: blue; text-decoration: underline;"> https://www.paypal.com/businessprofile/settings</a>
            in order to receive payments! You currently cannot receive payments. Once done, simply revisit this page to
            refresh the onboarding status.
          </span>
          <span class="d-block mx-w-35 validation-err-paypal" *ngIf="dataManager['paypalStatus'] &&
           dataManager['paypalStatus']['payments_receivable'] === false">
            Attention: You currently cannot receive payments due to possible restriction on your PayPal account. Please
            reach out to PayPal Customer Support or connect to
            <a href="https://www.paypal.com" target="_blank" rel="noopener noreferrer" style="color: blue;
            text-decoration: underline;"> https://www.paypal.com</a>
            for more information. Once sorted, simply revisit this page to refresh the onboarding status.
          </span>
          <div class="mt-2">
            <mat-slide-toggle
              *ngIf="dataManager['paypalStatus'] && dataManager['paypalStatus']['internal_status'] === 'boarded'"
              [(ngModel)]="settings.enable_paypal_payments" [disabled]="loadFlag"
              [color]="'primary'"
              (ngModelChange)="setEnablePaypal()">
              <img [src]="paypalLogoUrl" alt="PayPal Logo" class="paypal-logo"/>
            </mat-slide-toggle>
          </div>
          <div class="mt-2">
            <mat-slide-toggle
              *ngIf="dataManager['paypalStatus'] && dataManager['paypalStatus']['internal_status'] === 'boarded'"
              [(ngModel)]="settings.enable_paypal_paylater" [disabled]="loadFlag || !settings.enable_paypal_payments"
              [color]="'primary'"
              (ngModelChange)="setEnablePaypalPaylater()">
              <img [src]="payLaterLogoUrl" alt="Pay Later Logo" class="paylater-logo"/>
            </mat-slide-toggle>
          </div>
          <div class="mt-2">
            <mat-slide-toggle
              *ngIf="dataManager['paypalStatus'] && dataManager['paypalStatus']['internal_status'] === 'boarded'"
              [(ngModel)]="settings.enable_paypal_venmo" [disabled]="loadFlag  || !settings.enable_paypal_payments"
              [color]="'primary'"
              (ngModelChange)="setEnablePaypalVenmo()">
              <img [src]="venmoLogoUrl" alt="Venmo Logo" class="venmo-logo"/>
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
