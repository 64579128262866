import {BaseComponent} from '../abstract/base.component';
import {ErrorConstants} from '../shared/constants/error-constants';
import { Directive } from '@angular/core';

@Directive()
export abstract class AiReportsActionDirective extends BaseComponent {
  protected _apiGetUrl: string;

  protected getPageData() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._apiGetUrl, 0)
      .subscribe(
        (response) => {
          this.passPageData(response);
          if (response?.length === 0) {
            this.errorMsg = ErrorConstants.ApiErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.ApiErrorMsg);
          this.passApiError(error); }
      );
  }

  protected abstract passPageData(response);
  protected abstract passApiError(error);
}
