import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AppConstants} from '../../../shared/constants/app-constants';
import {AppUserModel} from '../../model/AppUser/app-user.model';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';
import {RoleModel} from '../../model/RoleModel/role.model';
import {PermissionModel} from '../../model/PermissionModel/permission.model';
import {MenuModel} from '../../model/MenuModel/menu.model';
import {UserAccountModel} from '../../model/UserAccountModel/user_account.model';

@Injectable()
export class DataManagerService {

  tag = 'app/data/services/DataManager/DataManagerService';
  protected httpClient: HttpClient;
  protected httpHeaders: HttpHeaders;
  public roles: RoleModel[];
  public permissionList: PermissionModel[];
  public filterUrl = new Subject<string>();
  public appLoader = false;
  public account_id: string;
  public user = {};
  protected httpFileUploadHeaders: HttpHeaders;
  protected httpHeaders2: HttpHeaders;
  protected requestOptions;
  protected headerToken: string;
  public userAccountInfoObject: UserAccountModel = new UserAccountModel();
  public accessToken: string;
  public logoUrl: string;
  public loginlogo: string;
  // public currentUser1: AppUserModel;
  public currentUser: AppUserModel;
  public menuArray: MenuModel[] = [];
  public userAccountInfo: UserAccountModel[] = [];
  public permissionsArray: PermissionModel[] = [];
  public paypalStatus: [];
  prev_url: string;
  disbursementReportUrl: string;
  disbursementReportFrom: string;
  disbursementReportTo: string;
  disputeReportUrl: string;
  disputeReportFrom: string;
  disputeReportTo: string;
  transactionReportUrl: string;
  transactionReport: any;
  transactionSuccessReportUrl: string;
  transactionUnSuccessReportUrl: string;
  constructor(httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any, private router: Router) {
    console.log('constructor in data manager');
    this.roles = [];
    this.httpClient = httpClient;
    this.currentUser = new AppUserModel();
    this.httpHeaders2 = new HttpHeaders().set('Content-Type', 'application/json');
    this.httpFileUploadHeaders = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    if (isPlatformBrowser(this.platformId)) {
      // localStorage will be available: we can use it.
    }

  }

  /**
   *func to set permissions in permission list
   * @param roleList
   */
  setPermissions(roleList: RoleModel[]) {
    for (const role of roleList) {
      for (const permission of role.permissions.data) {
        this.permissionList.push(permission);
      }
    }
  }

  /**
   * func to push filter list to component
   * @param url
   */
  setFilterUrl(url: string) {
    this.filterUrl.next(url);

  }

  /**
   * func to check if access token is present
   * @returns {boolean}
   */
  accessTokenExists() {
    return this.accessToken ? true : false;
  }

  /**
   * func to return name for
   * loggedIn/registered user
   * @returns {string}
   */
  getCurrentUserName() {
    return this.currentUser.name;
  }

  /**
   * func to get current
   * logged in user id
   * @returns {string}
   */
  getCurrentUserId() {
    return this.currentUser !== null ? this.currentUser.id : 1;
  }

  /**
   * func to initialize
   * subscribe on local
   * storage values
   */
  initializeLocalStorageItems() {
  }

  createHeaderWithUersAccessToken() {
    console.log('new object for header created');
    this.httpHeaders = new HttpHeaders().set('Authorization', this.accessToken);
    this.httpHeaders = this.httpHeaders.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.httpFileUploadHeaders.append('Authorization', this.accessToken);
  }

  /**
   * finc to set user data and header token
   * based on users login/register response
   * @param {AppUserModel} res response from api for loggedIn/registered users
   */
  setUserData(res) {
    console.log('start of data Set on localStorage');
    this.accessToken = res.token_type + ' ' + res.access_token;
    console.log(this.accessToken);
    this.createHeaderWithUersAccessToken();
    this.localStorage
      .setItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN, {token: this.accessToken})
      .subscribe(
        (response) => {
          console.log(response);
        }
      );
  }

  /**
   * func to login to server
   * with user creds
   * @param url
   * @param creds
   * @returns {Observable<any>}
   */
  login(url, creds): Observable<any> {
    return this.httpClient.post(url, creds, {headers: this.httpHeaders2});
  }

  /**
   * func to cal api using get Request
   * with HttpClient class
   * @param url api address
   * @param retry number of attempts in-case of no response
   * @returns {Observable<ArrayBuffer>} response returned
   */
  getRequest(url: string, retry: number): Observable<any> {
    // console.log({headers: this.httpHeaders});
    // console.log(url);
    return this.httpClient.get(url, {headers: this.httpHeaders});
  }

  getRequestPdf(url: string, retry: number): Observable<any> {

    return this.httpClient.get(url, {headers: this.httpHeaders, responseType: 'blob'});
  }
  getRequestExcel(url: string, retry: number): Observable<any> {
    return this.httpClient.get(url, {headers: this.httpHeaders, responseType: 'blob', observe: 'response'});
  }


  /**
   * func to make post request
   * for creating new records on server
   * @param url api address
   * @param payload payload for get requestF
   * @param retry number of attempts in-case of connection failure
   * @returns {MonoTypeOperatorFunction<>} response returned
   */
  postRequest(url: string, payload: any, retry: number): Observable<any> {
    console.log(this.tag, url);
    console.log(this.tag, payload);
    console.log(this.tag, retry);
    return this.httpClient.post(url, payload, {headers: this.httpHeaders});
  }

  postRequestResponseBolb(url: string, payload: any, retry: number): Observable<any> {
    // console.log({headers: this.httpHeaders});
    // console.log(url);
    return this.httpClient.post(url, payload, {headers: this.httpHeaders, responseType: 'blob'});
  }

  /**
   * func to make multipart post request
   * for creating new records on server
   * @param url api address
   * @param payload payload for get request
   * @param retry number of attempts in-case of connection failure
   * @returns {MonoTypeOperatorFunction<>} response returned
   */

  fileUpload(url: string, payload: any, retry: number): Observable<any> {
    console.log(this.tag, url);
    console.log(this.tag, payload);
    console.log(this.tag, retry);
    return this.httpClient.post(url, payload, {headers: this.httpHeaders});
  }

  /**
   * func to delete record on api using
   * http
   * @param url
   * @returns {Observable<any>}
   */
  deleteRequest(url): Observable<any> {
    return this.httpClient.delete(url, {headers: this.httpHeaders});
  }

  /**
   * func to make update request
   * on api data
   * @param url
   * @param putBody
   * @returns {Observable<any>}
   */
  putRequest(url, putBody): Observable<any> {
    return this.httpClient.put(url, putBody, {headers: this.httpHeaders});
  }

  /**
   * func to make post call without access token
   * @param url
   * @param postBody
   * @returns {Observable<any>}
   */
  authorizedPostCall(url, postBody): Observable<any> {

    return this.httpClient.post(url, postBody, {headers: this.httpHeaders});
  }

  /**
   * func to send patch request to api for
   * field update on record id
   * @param url
   * @param updateBody
   * @returns {Observable<any>}
   */
  patchRequest(url, updateBody): Observable<any> {
    return this.httpClient.patch(url, updateBody, {headers: this.httpHeaders});
  }

  /**
   * func to handle user/api logout
   */
  logOut() {
    this.deleteRequest(AppConstants.BASE_URL + AppConstants.SERVICE_NAME_LOGOUT)
      .subscribe(
        res => console.log(res),
        err => console.log(err)
      );
    console.log('local storage before', this.localStorage.getItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN));
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.clear();
      // this.localStorage.removeItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN);
      this.accessToken = null;
    }
    console.log('local storage', this.localStorage.getItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN));
    this.router.navigate(['/signin']);

  }

  appLoaderFlag(flag) {
    console.log('appLoaderFlag', flag, this.appLoader);
    this.appLoader !== flag ? this.appLoader = flag : console.log(this.appLoader, flag);
  }

  sendUserOtp(url: string, payload: any): Observable<any> {
    return this.httpClient.post(url, payload, {headers: this.httpHeaders});
  }

  verifyUserOtp(url: string, payload: any): Observable<any> {
    return this.httpClient.post(url, payload, {headers: this.httpHeaders});
  }

  PreferMethod(url: string, payload: any): Observable<any> {
    return this.httpClient.post(url, payload, {headers: this.httpHeaders});
  }
}
