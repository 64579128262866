export class MerchantSettingModel {
  tokenize_id?: string;
  disable_charge_notification_sms_id?: string;
  disable_charge_notification_email_id?: string;
  enable_charge_notification_sms_id?: string;
  enable_charge_notification_email_id?: string;
  enable_chargeback_notification_email_id?: string;
  enable_chargeback_notification_sms_id?: string;
  enable_deposit_notification_email_id?: string;
  enable_deposit_notification_sms_id?: string;
  display_transactional_metadata_id?: string;
  display_signature_pad_id?: boolean;
  display_prepaid_id?: boolean;
  tokenize?: boolean;
  disable_charge_notification_sms?: boolean;
  disable_charge_notification_email?: boolean;
  enable_charge_notification_sms?: boolean;
  enable_charge_notification_email?: boolean;
  enable_chargeback_notification_email?: boolean;
  enable_chargeback_notification_sms?: boolean;
  enable_deposit_notification_email?: boolean;
  enable_deposit_notification_sms?: boolean;
  display_transactional_metadata?: boolean;
  display_signature_pad?: boolean;
  display_prepaid?: boolean;
  enable_ach_payments?: boolean;
  allowed_ach_types?: string;
  debt_repayment_enabled?: boolean;
  enable_paypal_payments_id?: string;
  enable_paypal_payments?: boolean;
  enable_paypal_paylater_id?: string;
  enable_paypal_paylater?: boolean;
  enable_paypal_venmo_id?: string;
  enable_paypal_venmo?: boolean;
}
