import {SuccessfulTransactionModel} from '../../data/model/TransactionModel/transaction.model';
// import {environment} from '../../../environments/environment.staging' ;
import { environment } from '../../../environments/environment';
import {IdNameModel} from '../../data/model/UI/id-name.model';
import {PermissionHeadingModel} from '../../data/model/PermissionHeadingModel/permission-heading.model';

/**
 * Created by abhijeet on 5/2/18.
 */
export class AppConstants {


  public static BASE_URL = environment.base_url;
  public static GOOGLE_MAPS_API_KEY = 'AIzaSyAcCWBxULU867dkzkQ6-1fRKBGhteVAVlM';
  public static LOGO_BASE_URL = 'https://payarc-merchant-images.s3.amazonaws.com/';
  public static PAYPAL_LOGO_URL = 'assets/images/paypal.png';
  public static VENMO_LOGO_URL = 'assets/images/Venmo_Logo_Blue.svg';
  public static PAYLATER_LOGO_URL = 'assets/images/pay_later.png';
//  public static ApiKey = environment.api_key;
  public static SERVICE_NAME_CAMPAIGNS = '/campaigns';
  public static SERVICE_NAME_PAYEES = '/payees';
  public static ENABLE_TESTING_UI = environment.enable_test_ui;
  public static SHOW_PAYPAL = 'show_paypal' in environment ? environment['show_paypal'] : false;
  public static SERVICE_NAME_DISPUTE = '/disputes';
  public static SERVICE_NAME_CASES = '/cases';
  public static SERVICE_NAME_DISPUTE_CHART = '/dispute-chart';
  public static SERVICE_NAME_FILE = '/file';
  public static SERVICE_NAME_SYNC = '/sync';
  public static SERVICE_NAME_SUBMIT = '/submit';
  public static SERVICE_NAME_LOGIN = '/merchant/login';
  public static SERVICE_NAME_FORGOT_PASSWORD = '/password';
  public static SERVICE_NAME_LOGOUT = '/logout';
  public static SERVICE_NAME_CYCLE = '/plans';
  public static SERVICE_NAME_COUPONS = '/discounts';
  public static SERVICE_NAME_ACH_PAYLIANCE_DEBIT_CREDIT = '/achcharges';
  public static SERVICE_NAME_ACH_REJECTS = '/ach-rejects';
  public static SERVICE_NAME_SUBSCRIPTIONS = '/subscriptions';
  public static SERVICE_NAME_CUSTOMER = '/customers';
  public static SERVICE_NAME_CARDS = '/tokens';
  public static SERVICE_NAME_ACH_DETAILS = '/bankaccounts';
  public static SERVICE_NAME_CHARGE = '/charges';
  public static SERVICE_NAME_REGISTER = '/register';
  public static SERVICE_NAME_REFUND = '/refunds';
  public static SERVICE_NAME_WITHOUT_REFERENCE = '/wo_reference';
  public static SERVICE_NAME_FUNDS = '/funds';
  public static SERVICE_NAME_ACCOUNT = '/accounts';
  public static SERVICE_NAME_EVENT = '/events';
  public static SERVICE_NAME_INVOICES = '/invoices';
  public static SERVICE_NAME_PAYOUTS = '/payouts';
  public static SERVICE_NAME_TRANSACTION = '/transactions';
  public static SERVICE_NAME_TERMINALREGISTRIES = '/terminalregistries';
  public static SERVICE_NAME_SUMMARY = '/summary';
  public static SERVICE_NAME_PERMISSION = '/permissions';
  public static SERVICE_NAME_COLLECTED_FEES = '/application_fees';
  public static SERVICE_NAME_ROLE = '/roles';
  public static SERVICE_NAME_INVOICE_SETTING = '/invoicesettings';
  public static SERVICE_NAME_ACCOUNT_SETTING = '/accountsettings';
  public static SERVICE_NAME_ACCOUNT_SETTINGS = '/account-settings';
  public static SERVICE_NAME_PAYPAL_STATUS = '/account/setup_paypal/';
  public static SERVICE_NAME_INVOICE_MANUAL_SETTING = '/manualinvoicesetting';
  public static SERVICE_NAME_RISK = '/risks';
  public static SERVICE_NAME_REVIEW = '/reviews';
  public static SERVICE_NAME_USER_SETTINGS = '/user_settings';
  public static SERVICE_NAME_USERS = '/users';
  public static SERVICE_NAME_PLANS = '/plans';
  public static SERVICE_NAME_EVIDENCE = '/evidence';
  public static SERVICE_NAME_EVIDENCES = '/evidences';
  public static SERVICE_NAME_STATEMENTS = '/merchant_statements';
  public static SERVICE_NAME_MERCHANT_STATEMENTS = '/excel/charges';
  public static SERVICE_NAME_DOWNLOAD = '/download';
  public static SERVICE_NAME_TOKENIZE = '/tokenize';
  public static SERVICE_NAME_KOUNT = '/kount';
  public static SERVICE_NAME_REPORTS = '/reports';
  public static SERVICE_NAME_SEND_OTP = '/send';
  public static SERVICE_NAME_VERIFY_OTP = '/verify';
  public static SERVICE_NAME_VERIFY_OTP_IN_PROFILE = '/verify-setup-2fa';
  public static SERVICE_NAME_PREFERED_METHOD = '/preferred';
  /*action keys for api urls*/
  public static ACTION_VOID = '/void';
  public static ACTION_REVOKE = '/revoke';
  public static ACTION_ASSIGN = '/assign';
  /*relation data url keys*/
  public static SUBSCRIPTION_ID = 'subscription_id';
  public static CYCLE_ID = 'plan_id';
  public static COUPON_ID = 'discount_id';
  public static INVOICE_ID = 'invoice_id';
  /** export data url key */
  public static SERVICE_NAME_EXPORT_DASHBOARD = '/dashboard-export';
  /*constants for local storage keys*/
  public static STORAGE_KEY_USER = 'current_user';
  public static STORAGE_KEY_USER_ID = 'current_user_id';
  public static STORAGE_KEY_CURRENT_ACCOUNT_ID = 'current_account_id';
  public static STORAGE_KEY_CURRENT_ACCOUNT_NAME = 'current_account_name';
  public static STORAGE_KEY_USER_NAME = 'current_user_name';
  public static STORAGE_KEY_USER_EMAIL = 'current_user_email';
  public static STORAGE_KEY_IMPERSONATE_USER_ID = 'impersonating_user_id';
  public static STORAGE_KEY_ACCESS_TOKEN = 'access_token';
  public static STORAGE_KEY_USER_MENU = 'user_menu';
  public static STORAGE_KEY_FIRST_MENU = 'first_menu';
  public static STORAGE_KEY_RISKS = 'risks';
  public static STORAGE_KEY_USER_PERMISSIONS = 'user_permissions';
  public static STORAGE_KEY_DISBURSEMENT_URL = 'disbursement_url';
  public static STORAGE_KEY_DISBURSEMENT_FROM = 'disbursement_from';
  public static STORAGE_KEY_DISBURSEMENT_TO = 'disbursement_to';
  public static STORAGE_KEY_DISPUTE_URL = 'dispute_url';
  public static STORAGE_KEY_DISPUTE_FROM = 'dispute_from';
  public static STORAGE_KEY_DISPUTE_TO = 'dispute_to';
  public static STORAGE_KEY_HEADER_TOKEN = 'header_token';
  public static STORAGE_KEY_USER_ROLES = 'user_role';
  public static STORAGE_KEY_ACCOUNT_ID = 'account_id';
  public static STORAGE_KEY_ACCOUNT_SETTINGS = 'account_settings';
  public static STORAGE_KEY_ACCOUNT_CREATED_AT = 'created_at';
  public static STORAGE_KEY_TRANSACTION_SUCCESS_REPORT_URL = 'transaction_success_report';
  public static STORAGE_KEY_TRANSACTION_UNSUCCESS_REPORT_URL = 'transaction_unsuccess_report';
  public static STORAGE_KEY_TRANSACTION_REPORT_CONFIG = 'transaction_report_config';
  public static DATE_FORMAT = 'MM/dd/yyyy';
  /* action keys for api urls*/
  /*static data for ui*/
  static cardValideLength = {
    'visa': [19, 16, 13],
    'jcb': [19, 16],
    'discover': [19, 16],
    'amex': [15],
    'mastercard': [16],
    'dinersclub': [16, 14]
  };
  public static permissionHeading: PermissionHeadingModel[] = [
    {
      heading: 'Home Dashboard',
      key: 'home_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Customer Management',
      key: 'customer_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Subscription Management',
      key: 'subscription_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Plan Management',
      key: 'plan_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Discount Management',
      key: 'discount_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Payment Management',
      key: 'payment_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Dispute Management',
      key: 'dispute_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Payout Management',
      key: 'payout_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Transactions',
      key: 'transaction',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Reporting',
      key: 'reporting',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Risks',
      key: 'risks',
      permissions: [],
      assigned: false
    },
    {
      heading: 'User Management',
      key: 'user_management',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Recurring Billing',
      key: 'recurring_billing',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Statements',
      key: 'statements',
      permissions: [],
      assigned: false
    },
    {
      heading: 'Merchant Account',
      key: 'merchant_account',
      permissions: []
    },{
      heading: 'Notification Management',
      key: 'notification_management',
      permissions: [],
      assigned: false
    }
    // Removed this permission and  backend also unchecked the permission in accounts api
    // ,{
    //   heading: 'Account Management',
    //   key: 'manage-accounts',
    //   permissions: []
    // }
  ];


  public static transactionList = [
    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },

    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },

    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },

    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },
    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },
    {
      type: 'charge',
      net: 867,
      amount: 567,
      fee: 4.19,
      description: 'ch_1gbzksxZkscZmcmk',
      available_on: new Date('January 10, 2018 12:13:00')

    },
  ];

  public static countryCodes = [
    {val: 'ABW', text: 'Aruba'},
    {val: 'AFG', text: 'Afghanistan'},
    {val: 'AGO', text: 'Angola'},
    {val: 'AIA', text: 'Anguilla'},
    {val: 'ALA', text: 'Åland Islands'},
    {val: 'ALB', text: 'Albania'},
    {val: 'AND', text: 'Andorra'},
    {val: 'ARE', text: 'United Arab Emirates'},
    {val: 'ARG', text: 'Argentina'},
    {val: 'ARM', text: 'Armenia'},
    {val: 'ASM', text: 'American Samoa'},
    {val: 'ATA', text: 'Antarctica'},
    {val: 'ATF', text: 'French Southern Territories'},
    {val: 'ATG', text: 'Antigua and Barbuda'},
    {val: 'AUS', text: 'Australia'},
    {val: 'AUT', text: 'Austria'},
    {val: 'AZE', text: 'Azerbaijan'},
    {val: 'BDI', text: 'Burundi'},
    {val: 'BEL', text: 'Belgium'},
    {val: 'BEN', text: 'Benin'},
    {val: 'BES', text: 'Bonaire, Sint Eustatius and Saba'},
    {val: 'BFA', text: 'Burkina Faso'},
    {val: 'BGD', text: 'Bangladesh'},
    {val: 'BGR', text: 'Bulgaria'},
    {val: 'BHR', text: 'Bahrain'},
    {val: 'BHS', text: 'Bahamas'},
    {val: 'BIH', text: 'Bosnia and Herzegovina'},
    {val: 'BLM', text: 'Saint Barthélemy'},
    {val: 'BLR', text: 'Belarus'},
    {val: 'BLZ', text: 'Belize'},
    {val: 'BMU', text: 'Bermuda'},
    {val: 'BOL', text: 'Bolivia (Plurinational State of)'},
    {val: 'BRA', text: 'Brazil'},
    {val: 'BRB', text: 'Barbados'},
    {val: 'BRN', text: 'Brunei Darussalam'},
    {val: 'BTN', text: 'Bhutan'},
    {val: 'BVT', text: 'Bouvet Island'},
    {val: 'BWA', text: 'Botswana'},
    {val: 'CAF', text: 'Central African Republic'},
    {val: 'CAN', text: 'Canada'},
    {val: 'CCK', text: 'Cocos (Keeling) Islands'},
    {val: 'CHE', text: 'Switzerland'},
    {val: 'CHL', text: 'Chile'},
    {val: 'CHN', text: 'China'},
    {val: 'CIV', text: 'Côte d\'Ivoire'},
    {val: 'CMR', text: 'Cameroon'},
    {val: 'COD', text: 'Congo, Democratic Republic of the'},
    {val: 'COG', text: 'Congo'},
    {val: 'COK', text: 'Cook Islands'},
    {val: 'COL', text: 'Colombia'},
    {val: 'COM', text: 'Comoros'},
    {val: 'CPV', text: 'Cabo Verde'},
    {val: 'CRI', text: 'Costa Rica'},
    {val: 'CUB', text: 'Cuba'},
    {val: 'CUW', text: 'Curaçao'},
    {val: 'CXR', text: 'Christmas Island'},
    {val: 'CYM', text: 'Cayman Islands'},
    {val: 'CYP', text: 'Cyprus'},
    {val: 'CZE', text: 'Czechia'},
    {val: 'DEU', text: 'Germany'},
    {val: 'DJI', text: 'Djibouti'},
    {val: 'DMA', text: 'Dominica'},
    {val: 'DNK', text: 'Denmark'},
    {val: 'DOM', text: 'Dominican Republic'},
    {val: 'DZA', text: 'Algeria'},
    {val: 'ECU', text: 'Ecuador'},
    {val: 'EGY', text: 'Egypt'},
    {val: 'ERI', text: 'Eritrea'},
    {val: 'ESH', text: 'Western Sahara'},
    {val: 'ESP', text: 'Spain'},
    {val: 'EST', text: 'Estonia'},
    {val: 'ETH', text: 'Ethiopia'},
    {val: 'FIN', text: 'Finland'},
    {val: 'FJI', text: 'Fiji'},
    {val: 'FLK', text: 'Falkland Islands (Malvinas)'},
    {val: 'FRA', text: 'France'},
    {val: 'FRO', text: 'Faroe Islands'},
    {val: 'FSM', text: 'Micronesia (Federated States of)'},
    {val: 'GAB', text: 'Gabon'},
    {val: 'GBR', text: 'United Kingdom of Great Britain and Northern Ireland'},
    {val: 'GEO', text: 'Georgia'},
    {val: 'GGY', text: 'Guernsey'},
    {val: 'GHA', text: 'Ghana'},
    {val: 'GIB', text: 'Gibraltar'},
    {val: 'GIN', text: 'Guinea'},
    {val: 'GLP', text: 'Guadeloupe'},
    {val: 'GMB', text: 'Gambia'},
    {val: 'GNB', text: 'Guinea - Bissau'},
    {val: 'GNQ', text: 'Equatorial Guinea'},
    {val: 'GRC', text: 'Greece'},
    {val: 'GRD', text: 'Grenada'},
    {val: 'GRL', text: 'Greenland'},
    {val: 'GTM', text: 'Guatemala'},
    {val: 'GUF', text: 'French Guiana'},
    {val: 'GUM', text: 'Guam'},
    {val: 'GUY', text: 'Guyana'},
    {val: 'HKG', text: 'Hong Kong'},
    {val: 'HMD', text: 'Heard Island and McDonald Islands'},
    {val: 'HND', text: 'Honduras'},
    {val: 'HRV', text: 'Croatia'},
    {val: 'HTI', text: 'Haiti'},
    {val: 'HUN', text: 'Hungary'},
    {val: 'IDN', text: 'Indonesia'},
    {val: 'IMN', text: 'Isle of Man'},
    {val: 'IND', text: 'India'},
    {val: 'IOT', text: 'British Indian Ocean Territory'},
    {val: 'IRL', text: 'Ireland'},
    {val: 'IRN', text: 'Iran (Islamic Republic of)'},
    {val: 'IRQ', text: 'Iraq'},
    {val: 'ISL', text: 'Iceland'},
    {val: 'ISR', text: 'Israel'},
    {val: 'ITA', text: 'Italy'},
    {val: 'JAM', text: 'Jamaica'},
    {val: 'JEY', text: 'Jersey'},
    {val: 'JOR', text: 'Jordan'},
    {val: 'JPN', text: 'Japan'},
    {val: 'KAZ', text: 'Kazakhstan'},
    {val: 'KEN', text: 'Kenya'},
    {val: 'KGZ', text: 'Kyrgyzstan'},
    {val: 'KHM', text: 'Cambodia'},
    {val: 'KIR', text: 'Kiribati'},
    {val: 'KNA', text: 'Saint Kitts and Nevis'},
    {val: 'KOR', text: 'Korea, Republic of'},
    {val: 'KWT', text: 'Kuwait'},
    {val: 'LAO', text: 'Lao People\'s Democratic Republic'},
    {val: 'LBN', text: 'Lebanon'},
    {val: 'LBR', text: 'Liberia'},
    {val: 'LBY', text: 'Libya'},
    {val: 'LCA', text: 'Saint Lucia'},
    {val: 'LIE', text: 'Liechtenstein'},
    {val: 'LKA', text: 'Sri Lanka'},
    {val: 'LSO', text: 'Lesotho'},
    {val: 'LTU', text: 'Lithuania'},
    {val: 'LUX', text: 'Luxembourg'},
    {val: 'LVA', text: 'Latvia'},
    {val: 'MAC', text: 'Macao'},
    {val: 'MAF', text: 'Saint Martin (French part)'},
    {val: 'MAR', text: 'Morocco'},
    {val: 'MCO', text: 'Monaco'},
    {val: 'MDA', text: 'Moldova, Republic of'},
    {val: 'MDG', text: 'Madagascar'},
    {val: 'MDV', text: 'Maldives'},
    {val: 'MEX', text: 'Mexico'},
    {val: 'MHL', text: 'Marshall Islands'},
    {val: 'MKD', text: 'North Macedonia'},
    {val: 'MLI', text: 'Mali'},
    {val: 'MLT', text: 'Malta'},
    {val: 'MMR', text: 'Myanmar'},
    {val: 'MNE', text: 'Montenegro'},
    {val: 'MNG', text: 'Mongolia'},
    {val: 'MNP', text: 'Northern Mariana Islands'},
    {val: 'MOZ', text: 'Mozambique'},
    {val: 'MRT', text: 'Mauritania'},
    {val: 'MSR', text: 'Montserrat'},
    {val: 'MTQ', text: 'Martinique'},
    {val: 'MUS', text: 'Mauritius'},
    {val: 'MWI', text: 'Malawi'},
    {val: 'MYS', text: 'Malaysia'},
    {val: 'MYT', text: 'Mayotte'},
    {val: 'NAM', text: 'Namibia'},
    {val: 'NCL', text: 'New Caledonia'},
    {val: 'NER', text: 'Niger'},
    {val: 'NFK', text: 'Norfolk Island'},
    {val: 'NGA', text: 'Nigeria'},
    {val: 'NIC', text: 'Nicaragua'},
    {val: 'NIU', text: 'Niue'},
    {val: 'NLD', text: 'Netherlands'},
    {val: 'NOR', text: 'Norway'},
    {val: 'NPL', text: 'Nepal'},
    {val: 'NRU', text: 'Nauru'},
    {val: 'NZL', text: 'New Zealand'},
    {val: 'OMN', text: 'Oman'},
    {val: 'PAK', text: 'Pakistan'},
    {val: 'PAN', text: 'Panama'},
    {val: 'PCN', text: 'Pitcairn'},
    {val: 'PER', text: 'Peru'},
    {val: 'PHL', text: 'Philippines'},
    {val: 'PLW', text: 'Palau'},
    {val: 'PNG', text: 'Papua New Guinea'},
    {val: 'POL', text: 'Poland'},
    {val: 'PRI', text: 'Puerto Rico'},
    {val: 'PRK', text: 'Korea (Democratic People\'s Republic of)'},
    {val: 'PRT', text: 'Portugal'},
    {val: 'PRY', text: 'Paraguay'},
    {val: 'PSE', text: 'Palestine, State of'},
    {val: 'PYF', text: 'French Polynesia'},
    {val: 'QAT', text: 'Qatar'},
    {val: 'REU', text: 'Réunion'},
    {val: 'ROU', text: 'Romania'},
    {val: 'RUS', text: 'Russian Federation'},
    {val: 'RWA', text: 'Rwanda'},
    {val: 'SAU', text: 'Saudi Arabia'},
    {val: 'SDN', text: 'Sudan'},
    {val: 'SEN', text: 'Senegal'},
    {val: 'SGP', text: 'Singapore'},
    {val: 'SGS', text: 'South Georgia and the South Sandwich Islands'},
    {val: 'SHN', text: 'Saint Helena, Ascension and Tristan da Cunha'},
    {val: 'SJM', text: 'Svalbard and Jan Mayen'},
    {val: 'SLB', text: 'Solomon Islands'},
    {val: 'SLE', text: 'Sierra Leone'},
    {val: 'SLV', text: 'El Salvador'},
    {val: 'SMR', text: 'San Marino'},
    {val: 'SOM', text: 'Somalia'},
    {val: 'SPM', text: 'Saint Pierre and Miquelon'},
    {val: 'SRB', text: 'Serbia'},
    {val: 'SSD', text: 'South Sudan'},
    {val: 'STP', text: 'Sao Tome and Principe'},
    {val: 'SUR', text: 'Suriname'},
    {val: 'SVK', text: 'Slovakia'},
    {val: 'SVN', text: 'Slovenia'},
    {val: 'SWE', text: 'Sweden'},
    {val: 'SWZ', text: 'Eswatini'},
    {val: 'SXM', text: 'Sint Maarten (Dutch part)'},
    {val: 'SYC', text: 'Seychelles'},
    {val: 'SYR', text: 'Syrian Arab Republic'},
    {val: 'TCA', text: 'Turks and Caicos Islands'},
    {val: 'TCD', text: 'Chad'},
    {val: 'TGO', text: 'Togo'},
    {val: 'THA', text: 'Thailand'},
    {val: 'TJK', text: 'Tajikistan'},
    {val: 'TKL', text: 'Tokelau'},
    {val: 'TKM', text: 'Turkmenistan'},
    {val: 'TLS', text: 'Timor-Leste'},
    {val: 'TON', text: 'Tonga'},
    {val: 'TTO', text: 'Trinidad and Tobago'},
    {val: 'TUN', text: 'Tunisia'},
    {val: 'TUR', text: 'Turkey'},
    {val: 'TUV', text: 'Tuvalu'},
    {val: 'TWN', text: 'Taiwan, Province of China'},
    {val: 'TZA', text: 'Tanzania, United Republic of'},
    {val: 'UGA', text: 'Uganda'},
    {val: 'UKR', text: 'Ukraine'},
    {val: 'UMI', text: 'United States Minor Outlying Islands'},
    {val: 'URY', text: 'Uruguay'},
    {val: 'USA', text: 'United States of America'},
    {val: 'UZB', text: 'Uzbekistan'},
    {val: 'VAT', text: 'Holy See'},
    {val: 'VCT', text: 'Saint Vincent and the Grenadines'},
    {val: 'VEN', text: 'Venezuela (Bolivarian Republic of)'},
    {val: 'VGB', text: 'Virgin Islands (British)'},
    {val: 'VIR', text: 'Virgin Islands (U.S.)'},
    {val: 'VNM', text: 'Viet Nam'},
    {val: 'VUT', text: 'Vanuatu'},
    {val: 'WLF', text: 'Wallis and Futuna'},
    {val: 'WSM', text: 'Samoa'},
    {val: 'YEM', text: 'Yemen'},
    {val: 'ZAF', text: 'South Africa'},
    {val: 'ZMB', text: 'Zambia'},
    {val: 'ZWE', text: 'Zimbabwe'}
  ];

  public static successfultransactionList: SuccessfulTransactionModel[] = [
    {
      'transaction_type': 'Sales',
      'authorized_amount': 0.0,
      'settlement_amount': 0.0,
      'settled_amount': 5678.54,
      'total': 5678.54,

    },

    {
      'transaction_type': 'Individual',
      'authorized_amount': 0.0,
      'settlement_amount': 0.0,
      'settled_amount': 5678.54,
      'total': 5678.54,

    },

    {
      'transaction_type': 'Credit',
      'authorized_amount': 0.0,
      'settlement_amount': 0.0,
      'settled_amount': 5678.54,
      'total': 5678.54,

    },

    {
      'transaction_type': 'Total',
      'authorized_amount': 0.0,
      'settlement_amount': 0.0,
      'settled_amount': 5678.54,
      'total': 5678.54,

    },

    {
      'transaction_type': 'Individual',
      'authorized_amount': 0.0,
      'settlement_amount': 0.0,
      'settled_amount': 5678.54,
      'total': 5678.54,

    },
  ];


  public static monthList = [
    {value: 1, text: '01'},
    {value: 2, text: '02'},
    {value: 3, text: '03'},
    {value: 4, text: '04'},
    {value: 5, text: '05'},
    {value: 6, text: '06'},
    {value: 7, text: '07'},
    {value: 8, text: '08'},
    {value: 9, text: '09'},
    {value: 10, text: '10'},
    {value: 11, text: '11'},
    {value: 12, text: '12'},
  ];


  public static createNewCycleIntervalList: IdNameModel[] = [
    {
      id: 1,
      name: 'Daily',
      key: 'day'
    },
    {
      id: 2,
      name: 'Weekly',
      key: 'week'
    },
    {
      id: 3,
      name: 'Monthly',
      key: 'month'
    },
    {
      id: 4,
      name: 'Yearly',
      key: 'year'
    },
    {
      id: 4,
      name: 'Custom',
      key: 'custom'
    },
  ];

  public static couponDurationList: IdNameModel[] = [
    {
      id: 1,
      name: 'Once',
      key: 'once',
    },
    {
      id: 2,
      name: 'Multi-month',
      key: 'repeating'
    },
    {
      id: 3,
      name: 'Forever',
      key: 'forever'
    }
  ];
  public static currencyList: IdNameModel[] = [
    {
      id: 1,
      name: 'USD-US Dollar',
      key: 'usd'
    }
  ];

  public static countryList: IdNameModel[] = [
    {
      id: 1,
      name: 'United States',
      key: 'US'
    },
    {
      id: 2,
      name: 'Canada',
      key: 'CA'
    }
  ];

  public static canadaStateList: IdNameModel[] = [
    {
      id: 1,
      name: 'Alberta',
      key: 'AB'
    },
    {
      id: 2,
      name: 'British Columbia',
      key: 'BC'
    },
    {
      id: 3,
      name: 'Manitoba',
      key: 'MB'
    },
    {
      id: 4,
      name: 'New Brunswick',
      key: 'NB'
    },
    {
      id: 5,
      name: 'Newfoundland and Labrador',
      key: 'NL'
    },
    {
      id: 6,
      name: 'Northwest Territories',
      key: 'NT'
    },
    {
      id: 7,
      name: 'Nova Scotia',
      key: 'NS'
    },
    {
      id: 8,
      name: 'Nunavut',
      key: 'NU'
    },
    {
      id: 9,
      name: 'Ontario',
      key: 'ON'
    },
    {
      id: 10,
      name: 'Prince Edward Island',
      key: 'PE'
    },
    {
      id: 11,
      name: 'Quebec',
      key: 'QC'
    },
    {
      id: 12,
      name: 'Saskatchewan',
      key: 'SK'
    },
    {
      id: 13,
      name: 'Yukon',
      key: 'YT'
    }
  ];

  public static stateList: IdNameModel[] = [
    {
      id: 1,
      name: 'Alabama',
      key: 'Alabama'
    },
    {
      id: 2,
      name: 'Alaska',
      key: 'Alaska'
    },
    {
      id: 3,
      name: 'Arizona',
      key: 'Arizona'
    },
    {
      id: 4,
      name: 'Arkansas',
      key: 'Arkansas'
    },
    {
      id: 5,
      name: 'California',
      key: 'California'
    },
    {
      id: 6,
      name: 'Colorado',
      key: 'Colorado'
    },
    {
      id: 7,
      name: 'Connecticut',
      key: 'Connecticut'
    },
    {
      id: 8,
      name: 'Delaware',
      key: 'Delaware'
    },
    {
      id: 9,
      name: 'District of Columbia',
      key: 'District of Columbia'
    },
    {
      id: 10,
      name: 'Florida',
      key: 'Florida'
    },
    {
      id: 11,
      name: 'Georgia',
      key: 'Georgia'
    },
    {
      id: 12,
      name: 'Hawaii',
      key: 'Hawaii'
    },
    {
      id: 13,
      name: 'Idaho',
      key: 'Idaho'
    },
    {
      id: 14,
      name: 'Illinois',
      key: 'Illinois'
    },
    {
      id: 15,
      name: 'Indiana',
      key: 'Indiana'
    },
    {
      id: 16,
      name: 'Iowa',
      key: 'Iowa'
    },
    {
      id: 17,
      name: 'Kansas',
      key: 'Kansas'
    },
    {
      id: 18,
      name: 'Kentucky',
      key: 'Kentucky'
    },
    {
      id: 19,
      name: 'Louisiana',
      key: 'Louisiana'
    },
    {
      id: 20,
      name: 'Maine',
      key: 'Maine'
    },
    {
      id: 21,
      name: 'Maryland',
      key: 'Maryland'
    },
    {
      id: 22,
      name: 'Massachusetts',
      key: 'Massachusetts'
    },
    {
      id: 23,
      name: 'Michigan',
      key: 'Michigan'
    },
    {
      id: 24,
      name: 'Minnesota',
      key: 'Minnesota'
    },
    {
      id: 25,
      name: 'Mississippi',
      key: 'Mississippi'
    },
    {
      id: 26,
      name: 'Missouri',
      key: 'Missouri'
    },
    {
      id: 27,
      name: 'Montana',
      key: 'Montana'
    },
    {
      id: 28,
      name: 'Nebraska',
      key: 'Nebraska'
    },
    {
      id: 29,
      name: 'Nevada',
      key: 'Nevada'
    },
    {
      id: 30,
      name: 'New Hampshire',
      key: 'New Hampshire'
    },
    {
      id: 31,
      name: 'New Jersey',
      key: 'New Jersey'
    },
    {
      id: 32,
      name: 'New Mexico',
      key: 'New Mexico'
    },
    {
      id: 33,
      name: 'New York',
      key: 'New York'
    },
    {
      id: 34,
      name: 'North Carolina',
      key: 'North Carolina'
    },
    {
      id: 35,
      name: 'North Dakota',
      key: 'North Dakota'
    },
    {
      id: 36,
      name: 'Ohio',
      key: 'Ohio'
    },
    {
      id: 37,
      name: 'Oklahoma',
      key: 'Oklahoma'
    },
    {
      id: 38,
      name: 'Oregon',
      key: 'Oregon'
    },
    {
      id: 39,
      name: 'Pennsylvania',
      key: 'Pennsylvania'
    },
    {
      id: 40,
      name: 'Puerto Rico',
      key: 'Puerto Rico'
    },
    {
      id: 41,
      name: 'Rhode Island',
      key: 'Rhode Island'
    },
    {
      id: 42,
      name: 'South Carolina',
      key: 'South Carolina'
    },
    {
      id: 43,
      name: 'South Dakota',
      key: 'South Dakota'
    },
    {
      id: 44,
      name: 'Tennessee',
      key: 'Tennessee'
    },
    {
      id: 45,
      name: 'Texas',
      key: 'Texas'
    },
    {
      id: 46,
      name: 'Utah',
      key: 'Utah'
    },
    {
      id: 47,
      name: 'Vermont',
      key: 'Vermont'
    },
    {
      id: 48,
      name: 'Virginia',
      key: 'Virginia'
    },
    {
      id: 49,
      name: 'Washington',
      key: 'Washington'
    },
    {
      id: 50,
      name: 'West Virginia',
      key: 'West Virginia'
    },
    {
      id: 51,
      name: 'Wisconsin',
      key: 'Wisconsin'
    },
    {
      id: 52,
      name: 'Wyoming',
      key: 'Wyoming'
    }
  ];
  public static country_codes =
    {
      'Afghanistan': 'AF',
      'Åland Islands': 'AX',
      'Albania': 'AL',
      'Algeria': 'DZ',
      'American Samoa': 'AS',
      'Andorra': 'AD',
      'Angola': 'AO',
      'Anguilla': 'AI',
      'Antarctica': 'AQ',
      'Antigua and Barbuda': 'AG',
      'Argentina': 'AR',
      'Armenia': 'AM',
      'Aruba': 'AW',
      'Australia': 'AU',
      'Austria': 'AT',
      'Azerbaijan': 'AZ',
      'Bahamas': 'BS',
      'Bahrain': 'BH',
      'Bangladesh': 'BD',
      'Barbados': 'BB',
      'Belarus': 'BY',
      'Belgium': 'BE',
      'Belize': 'BZ',
      'Benin': 'BJ',
      'Bermuda': 'BM',
      'Bhutan': 'BT',
      'Bolivia, Plurinational State of': 'BO',
      'Bonaire, Sint Eustatius and Saba': 'BQ',
      'Bosnia and Herzegovina': 'BA',
      'Botswana': 'BW',
      'Bouvet Island': 'BV',
      'Brazil': 'BR',
      'British Indian Ocean Territory': 'IO',
      'Brunei Darussalam': 'BN',
      'Bulgaria': 'BG',
      'Burkina Faso': 'BF',
      'Burundi': 'BI',
      'Cambodia': 'KH',
      'Cameroon': 'CM',
      'Canada': 'CA',
      'Cape Verde': 'CV',
      'Cayman Islands': 'KY',
      'Central African Republic': 'CF',
      'Chad': 'TD',
      'Chile': 'CL',
      'China': 'CN',
      'Christmas Island': 'CX',
      'Cocos (Keeling) Islands': 'CC',
      'Colombia': 'CO',
      'Comoros': 'KM',
      'Congo': 'CG',
      'Congo, the Democratic Republic of the': 'CD',
      'Cook Islands': 'CK',
      'Costa Rica': 'CR',
      'Côte d\'Ivoire': 'CI',
      'Croatia': 'HR',
      'Cuba': 'CU',
      'Curaçao': 'CW',
      'Cyprus': 'CY',
      'Czech Republic': 'CZ',
      'Denmark': 'DK',
      'Djibouti': 'DJ',
      'Dominica': 'DM',
      'Dominican Republic': 'DO',
      'Ecuador': 'EC',
      'Egypt': 'EG',
      'El Salvador': 'SV',
      'Equatorial Guinea': 'GQ',
      'Eritrea': 'ER',
      'Estonia': 'EE',
      'Ethiopia': 'ET',
      'Falkland Islands (Malvinas)': 'FK',
      'Faroe Islands': 'FO',
      'Fiji': 'FJ',
      'Finland': 'FI',
      'France': 'FR',
      'French Guiana': 'GF',
      'French Polynesia': 'PF',
      'French Southern Territories': 'TF',
      'Gabon': 'GA',
      'Gambia': 'GM',
      'Georgia': 'GE',
      'Germany': 'DE',
      'Ghana': 'GH',
      'Gibraltar': 'GI',
      'Greece': 'GR',
      'Greenland': 'GL',
      'Grenada': 'GD',
      'Guadeloupe': 'GP',
      'Guam': 'GU',
      'Guatemala': 'GT',
      'Guernsey': 'GG',
      'Guinea': 'GN',
      'Guinea-Bissau': 'GW',
      'Guyana': 'GY',
      'Haiti': 'HT',
      'Heard Island and McDonald Islands': 'HM',
      'Holy See (Vatican City State)': 'VA',
      'Honduras': 'HN',
      'Hong Kong': 'HK',
      'Hungary': 'HU',
      'Iceland': 'IS',
      'India': 'IN',
      'Indonesia': 'ID',
      'Iran, Islamic Republic of': 'IR',
      'Iraq': 'IQ',
      'Ireland': 'IE',
      'Isle of Man': 'IM',
      'Israel': 'IL',
      'Italy': 'IT',
      'Jamaica': 'JM',
      'Japan': 'JP',
      'Jersey': 'JE',
      'Jordan': 'JO',
      'Kazakhstan': 'KZ',
      'Kenya': 'KE',
      'Kiribati': 'KI',
      'Korea, Democratic People\'s Republic of': 'KP',
      'Korea, Republic of': 'KR',
      'Kuwait': 'KW',
      'Kyrgyzstan': 'KG',
      'Lao People\'s Democratic Republic': 'LA',
      'Latvia': 'LV',
      'Lebanon': 'LB',
      'Lesotho': 'LS',
      'Liberia': 'LR',
      'Libya': 'LY',
      'Liechtenstein': 'LI',
      'Lithuania': 'LT',
      'Luxembourg': 'LU',
      'Macao': 'MO',
      'Macedonia, the Former Yugoslav Republic of': 'MK',
      'Madagascar': 'MG',
      'Malawi': 'MW',
      'Malaysia': 'MY',
      'Maldives': 'MV',
      'Mali': 'ML',
      'Malta': 'MT',
      'Marshall Islands': 'MH',
      'Martinique': 'MQ',
      'Mauritania': 'MR',
      'Mauritius': 'MU',
      'Mayotte': 'YT',
      'Mexico': 'MX',
      'Micronesia, Federated States of': 'FM',
      'Moldova, Republic of': 'MD',
      'Monaco': 'MC',
      'Mongolia': 'MN',
      'Montenegro': 'ME',
      'Montserrat': 'MS',
      'Morocco': 'MA',
      'Mozambique': 'MZ',
      'Myanmar': 'MM',
      'Namibia': 'NA',
      'Nauru': 'NR',
      'Nepal': 'NP',
      'Netherlands': 'NL',
      'New Caledonia': 'NC',
      'New Zealand': 'NZ',
      'Nicaragua': 'NI',
      'Niger': 'NE',
      'Nigeria': 'NG',
      'Niue': 'NU',
      'Norfolk Island': 'NF',
      'Northern Mariana Islands': 'MP',
      'Norway': 'NO',
      'Oman': 'OM',
      'Pakistan': 'PK',
      'Palau': 'PW',
      'Palestine, State of': 'PS',
      'Panama': 'PA',
      'Papua New Guinea': 'PG',
      'Paraguay': 'PY',
      'Peru': 'PE',
      'Philippines': 'PH',
      'Pitcairn': 'PN',
      'Poland': 'PL',
      'Portugal': 'PT',
      'Puerto Rico': 'PR',
      'Qatar': 'QA',
      'Réunion': 'RE',
      'Romania': 'RO',
      'Russian Federation': 'RU',
      'Rwanda': 'RW',
      'Saint Barthélemy': 'BL',
      'Saint Helena, Ascension and Tristan da Cunha': 'SH',
      'Saint Kitts and Nevis': 'KN',
      'Saint Lucia': 'LC',
      'Saint Martin (French part)': 'MF',
      'Saint Pierre and Miquelon': 'PM',
      'Saint Vincent and the Grenadines': 'VC',
      'Samoa': 'WS',
      'San Marino': 'SM',
      'Sao Tome and Principe': 'ST',
      'Saudi Arabia': 'SA',
      'Senegal': 'SN',
      'Serbia': 'RS',
      'Seychelles': 'SC',
      'Sierra Leone': 'SL',
      'Singapore': 'SG',
      'Sint Maarten (Dutch part)': 'SX',
      'Slovakia': 'SK',
      'Slovenia': 'SI',
      'Solomon Islands': 'SB',
      'Somalia': 'SO',
      'South Africa': 'ZA',
      'South Georgia and the South Sandwich Islands': 'GS',
      'South Sudan': 'SS',
      'Spain': 'ES',
      'Sri Lanka': 'LK',
      'Sudan': 'SD',
      'Suriname': 'SR',
      'Svalbard and Jan Mayen': 'SJ',
      'Swaziland': 'SZ',
      'Sweden': 'SE',
      'Switzerland': 'CH',
      'Syrian Arab Republic': 'SY',
      'Taiwan, Province of China': 'TW',
      'Tajikistan': 'TJ',
      'Tanzania, United Republic of': 'TZ',
      'Thailand': 'TH',
      'Timor-Leste': 'TL',
      'Togo': 'TG',
      'Tokelau': 'TK',
      'Tonga': 'TO',
      'Trinidad and Tobago': 'TT',
      'Tunisia': 'TN',
      'Turkey': 'TR',
      'Turkmenistan': 'TM',
      'Turks and Caicos Islands': 'TC',
      'Tuvalu': 'TV',
      'Uganda': 'UG',
      'Ukraine': 'UA',
      'United Arab Emirates': 'AE',
      'United Kingdom': 'GB',
      'United States': 'US',
      'United States Minor Outlying Islands': 'UM',
      'Uruguay': 'UY',
      'Uzbekistan': 'UZ',
      'Vanuatu': 'VU',
      'Venezuela, Bolivarian Republic of': 'VE',
      'Viet Nam': 'VN',
      'Virgin Islands, British': 'VG',
      'Virgin Islands, U.S.': 'VI',
      'Wallis and Futuna': 'WF',
      'Western Sahara': 'EH',
      'Yemen': 'YE',
      'Zambia': 'ZM',
      'Zimbabwe': 'ZW'
    };

  public static homeDayDifference = [
    {
      text: '1w',
      offset: 7,
      is_today_start: false,
      dateUnit: [
        {
          text: 'Daily',
          criteria: 'day',
          is_default: true
        }
      ]
    },
    {
      text: '4w',
      offset: 28,
      is_today_start: false,
      dateUnit: [
        {
          text: 'Weekly',
          criteria: 'week',
          is_default: false
        },
        {
          text: 'Daily',
          criteria: 'day',
          is_default: true
        }
      ]
    },
    {
      text: '1y',
      offset: 365,
      is_today_start: false,
      dateUnit: [
        {
          text: 'Weekly',
          criteria: 'week',
          is_default: true
        },
        {
          text: 'Monthly',
          criteria: 'month',
          is_default: false
        }
      ]
    },
    {
      text: 'Mtd',
      offset: 30,
      is_today_start: true,
      dateUnit: [
        {
          text: 'Daily',
          criteria: 'day',
          is_default: true
        }
      ]
    },
    {
      text: 'Qtd',
      offset: 90,
      is_today_start: true,
      dateUnit: [
        {
          text: 'Daily',
          criteria: 'day',
          is_default: true
        }
      ]
    },
    {
      text: 'Ytd',
      offset: 365,
      is_today_start: true,
      dateUnit: [
        {
          text: 'Monthly',
          criteria: 'month',
          is_default: true
        }
      ]
    },
    {
      text: 'All',
      offset: 0,
      is_today_start: false,
      dateUnit: []
    }
  ];

  public static homeDayUnitDifference = [
    {
      text: 'Daily',
      criteria: 'day',
    }
  ];

  public static avsParameters = [
    {
      key: 1,
      name: 'Void if AVS zip matches but AVS street fails',
    },
    {
      key: 2,
      name: 'Process if AVS zip matches but AVS street fails',
    },
    {
      key: 3,
      name: 'Void if AVS zip fails but AVS street matches',
    },
    {
      key: 4,
      name: 'Process if AVS zip fails but AVS street matches',
    },
    {
      key: 5,
      name: 'Void if AVS zip fails and AVS street fails',
    },
    {
      key: 6,
      name: 'Process if AVS zip fails and AVS street fails',
    }
  ];

  public static binLocationParameters = [
    {
      key: 1,
      name: 'Void if BIN is not USA',
    },
    {
      key: 2,
      name: 'Void if BIN is not USA or Canada',
    }
  ];

  public static binCardTypeParameters = [
    {
      key: 1,
      name: 'Void if BIN is credit card',
    },
    {
      key: 2,
      name: 'Void if BIN is debit card',
    },
    {
      key: 3,
      name: 'Void if BIN is prepaid card',
    },
    {
      key: 4,
      name: 'Void if BIN is prepaid or debit card',
    }
  ];

  public static getSuccessfulTransactionList(): SuccessfulTransactionModel[] {
    return this.successfultransactionList;
  }


  public static getValidCardNo(cardNo, cardType) {
    for (const length of this.cardValideLength[cardType]) {
      if (cardNo.length >= length) {
        console.log(cardNo.slice(0, length));
        return cardNo.slice(0, length);
      }
    }
  }


}
