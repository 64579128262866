import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../shared/constants/app-constants';
import { AddBankAccountAction } from './add-bank-account.action';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { CustomerV2Model } from '../../../data/model/CustomerV2Model/customer-v2.model';
import { BankAccountAchModel } from '../../../data/model/BankAchModel/bankaccountAch.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.css']
})
export class AddBankAccountComponent extends AddBankAccountAction  implements OnInit {
  topErrorMessage: string;
  errorMsgFlag: boolean;
  bankAccount:BankAccountAchModel;
  keys: String[];
  keys1: String[];
  errorMsg: string;
  customerId: string;
  customer: CustomerV2Model;
  createBankAccountForm:FormGroup;
  categories: string[] = ['Personal Checking', 'Personal Savings', 'Business Checking', 'Business Savings' ];

  constructor(dataManager: DataManagerService, private fb:FormBuilder, route: ActivatedRoute, router:Router,snackBar:MatSnackBar) {
    super();
    this._addBankAccountToCustomerUrl=AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACH_DETAILS;
    this.errorMsgFlag = false;
    this.dataManager = dataManager;
    this.bankAccount = new BankAccountAchModel();
    this.customerId = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA))['customer_ID'];
    this.router = router;
    this.route = route;
    this.snackBar = snackBar;
  }



  ngOnInit(): void {
    this.createBankAccountForm=this.fb.group({
      first_name: ['', [<any>Validators.required, <any>Validators.maxLength(50)]],
      last_name: ['', [<any>Validators.required,<any>Validators.maxLength(50)]],
      account_number: ['', [<any>Validators.minLength(3),<any>Validators.required, <any>Validators.maxLength(17)]],
      routing_number: ['', [<any>Validators.required, <any>Validators.maxLength(9),Validators.pattern(/^[0-9]+$/)]],
      account_type: ['', [<any>Validators.required]],
      company_name: ['', [<any>Validators.maxLength(50)]],
    });
    this.createBankAccountForm
      .get('account_type')
      .valueChanges.subscribe((value) => {
      console.log(value, 'value changed');
      const companyNameControl = this.createBankAccountForm.get('company_name');

      if (value === 'Business Checking' || value === 'Business Savings') {
        companyNameControl.setValidators([Validators.required, Validators.maxLength(50)]);
      } else {
        companyNameControl.setValidators([Validators.maxLength(50)]);
      }
      companyNameControl.updateValueAndValidity();
    });

  }
  enabledinpute() {
    this.createBankAccountForm.get('account_number').valid ?
      this.createBankAccountForm.get('prescription').enable()
      : this.createBankAccountForm.get('prescription').disable();
  }
  _keyPress(event: any) {
    this.bankInformaitionRequired();
    const keypressed = event.which || event.keyCode;
    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || keypressed === 46 // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
   /** @function setAchDetails() used to set invoice payment options
   * @param event
   * @param data
   * */
   setAchDetails(event) {
    this.bankAccount[event.target.id] = event.target.value;
   }
  protected  passSourceResponse(res) {

  };
  protected  passSourceError(error) {

  };
  protected  passResponse(response) {
    this.customer = response.data;
    this.goBackToDetails();
    this.showSnackBar('Customer created successfully.', 'Ok', 3000);

  };

  protected  passError(error) {
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.errorMsgFlag = true;
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    let i;
    let j;
    const name = ['first_name', 'last_name', 'account_number', 'routing_number', 'account_type'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  };

  createBankAccount(){
    let bankInformationBody = {};
    bankInformationBody['account_number'] = this.bankAccount.account_number ? this.bankAccount.account_number : null;
    bankInformationBody['routing_number'] = this.bankAccount.routing_number ? this.bankAccount.routing_number : null;
    console.log(this.bankAccount.account_number);
    bankInformationBody['first_name'] = this.bankAccount.first_name ? this.bankAccount.first_name.trim() : null;
    bankInformationBody['last_name'] = this.bankAccount.last_name ? this.bankAccount.last_name.trim() : null;
    bankInformationBody['account_type'] = this.bankAccount.account_type ? this.bankAccount.account_type : null;
    bankInformationBody['sec_code'] = this.bankAccount.sec_code ? this.bankAccount.sec_code : null;
    bankInformationBody['customer_id'] = this.customerId ? this.customerId : null;
    this.bankAccount.company_name ? bankInformationBody['company_name'] = this.bankAccount.company_name : null;

    this.bankInformaitionRequired();
    this.createBankAccountOnApi(bankInformationBody);

  }


  print(obj) {
    console.log(obj);
  }
  cancelBankAccount() {
    this.goBackToDetails();
  }
  goBackToDetails() {
    console.log(this.customerId,'goBackToDetails');
    this.router.navigate(['../customer-details'], {queryParams: {id: this.customerId},relativeTo : this.route});
  }

  bankInformaitionRequired(){
   this.createBankAccountForm.controls['account_type'].updateValueAndValidity();
   this.createBankAccountForm.controls['first_name'].updateValueAndValidity();
   this.createBankAccountForm.controls['last_name'].updateValueAndValidity();

   this.createBankAccountForm.controls['account_number'].updateValueAndValidity();
   this.createBankAccountForm.controls['routing_number'].updateValueAndValidity();
  }
}
