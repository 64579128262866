import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataManagerService} from '../data/services/DataManager/data-manager.service';
import {OnDestroy, OnInit, Directive} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppConstants} from '../shared/constants/app-constants';
import {Address} from 'ngx-google-places-autocomplete/objects/address';

/**
 * Created by abhijeet on 30/1/18.
 */
@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
  public dataManager: DataManagerService;
  protected router: Router;
  protected id: string = null;
  protected key: string = null;
  protected route: ActivatedRoute;
  public loadFlag: boolean;
  public loadFlag2: boolean;
  public errorMsg: string;
  public filterPostFix: string; // to be used in case of refresh
  protected snackBar: MatSnackBar;
  protected alertDialog: MatDialog;
  protected errorRedirectUrl: string;
  protected limit = 25;
  protected filterSubscription: Subscription;
  protected CUSTOMER_DATA = 'customer_data';
  /*used for list screens to set value for storage key*/
  filter_storage_key: string;
  count_storage_key: string;
  /*link redirect*/
  redirect_link: string;
  is_filter: boolean;
  from: any;
  to: any;
  category: any;
  scrollwidth = window.screen.width;
  contentscroll = 0;
  bsDateConfig = {
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false
  };
  /*for ui*/
  /**
   * var to hold permission key
   * to create new record
   */
  create_permission: boolean;
  /**
   * var to hold permission key
   * to delete record
   */
  delete_permission: boolean;
  /**
   * var to hold permission key
   * to update record
   */
  update_permission: boolean;
  /**
   * var to hold permission key
   * to list records
   */
  list_permission: boolean;
  /**
   * var to hold permission
   * key for list evennt
   */
  event_list_permission: boolean;
  /**
   * var to hold approve permission key
   * to update record
   */
  approve_permission: boolean;
  /**
   * var to hold reject permission key
   * to update record
   */
  reject_permission: boolean;
  user_email: string;
  user_mobile: any;
  is_2fa_email_verified: any;
  is_2fa_phone_verified: any;


  ngOnInit() {
  }

  /**
   * function to extract id from url params
   * this func is used in details screen
   * the redirect should be done from routeWithId()
   * func of this class
   * @returns {boolean}
   */
  protected fetchIdFromRouteQueryParams(): boolean {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
    });
    return this.id && this.id !== null ? true : false;
  }

  /**
   *
   * @returns {boolean}
   */
  protected fetchIdAndKeyFromRouteQueryParams(): boolean {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.key = params['key'];
    });
    return this.id && this.id !== null && this.key && this.key !== null
      ? true
      : false;
  }

  /*function for router class*/
  /**
   * func to route
   * @param route route to navigate to
   * @param options extra params sent with route
   */
  protected routeTo(route: string, options: NavigationExtras) {
    if (options !== null) {
      this.router.navigate([route], options);
    } else {
      this.router.navigate([route]);
    }
  }

  /**
   * func to route with id
   * @param route string route as per routing
   * @param id id for record to be oped on navigation
   */
  routeWithId(route: string, id): void {
    const navigationExtra: NavigationExtras = {queryParams: {id: id}};
    this.router.navigate([route], navigationExtra);
    // const url = this.router.serializeUrl(this.router.createUrlTree([route], navigationExtra));
    // window.open(url, '_blank');
  }


  routerWithIdNewTab(route: string, id): void {
    const navigationExtra: NavigationExtras = {queryParams: {id: id}};
    const url = this.router.serializeUrl(this.router.createUrlTree([route], navigationExtra));
    window.open(url, '_blank');
  }

  /**
   * func to route with id and key
   * @param {string} route
   * @param key
   * @param id
   */
  routeWithKeyAndId(route: string, key, id): void {
    const navigationExtra: NavigationExtras = {
      queryParams: {key: key, id: id},
    };
    //  this.router.navigate([route], navigationExtra);
    const url = this.router.serializeUrl(this.router.createUrlTree([route], navigationExtra));
    window.open(url, '_blank');
  }

  /**
   * func to route with navigation extra's
   * @param route string route as per routing
   * @param queryParams object of extra data
   */
  routeWithQueryParams(route: string, queryParams: {}) {
    const navigationExtra: NavigationExtras = {queryParams: queryParams};
    // this.router.navigate([route], navigationExtra);
    const url = this.router.serializeUrl(this.router.createUrlTree([route], navigationExtra));
    window.open(url, '_blank');
  }

  /**
   * func to route to given url
   * @param route url to route to
   */
  routeNavigate(route): void {
    this.router.navigate([route]);
  }

  /**
   * func to hanlde toast messages  on
   * sncakBar to show error message or action
   * @param message message to show
   * @param action Suggested Acttion Result
   * @param duration time duration the message should be shown
   */
  showSnackBar(message, action, duration) {
    console.log('showSnackBar', message, action, duration);

    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  /**
   * function to take url as argument
   * and after matching it to current url
   * it returns object or null
   * @param url
   * @returns {any}
   */
  matchUrl(url): any {
    return location.pathname.match(url);
  }

  /**
   * func to restrict the value of amount
   * can't less then or equal to $0.50
   * @param amount
   * @return {boolean}
   */
  validateAmount(amount) {
    console.log('Amount Validation', amount);
    if (amount < 0.01) {
      this.loadFlag = false;
      this.errorMsg = 'Amount less then $0.01 are not acceptable';
      return false;
    } else {
      return true;
    }
  }

  /**
   * func to handle errors
   * for api calls
   * @param error
   * @param Msg
   */
  networkErrorHandler(error, Msg = null) {
    console.error('errors property', Object.keys(error));
    try {
      if (
        Object.keys(error).some(
          (x) => (!isNaN(error[x]) && error[x] === 401) ||
            (typeof error[x] === 'string' && error[x].includes('Your session expired!'))
        )) {
        this.dataManager.logOut();
      }
      if (Msg) {
        // the next line is unrecognizable :)
        return Msg[Number(error.status_code)];
      }
    } catch (e) {
      console.log('networkErrorHandler', error);
      return null;
    }
    /*this.alertDialog.open(AlertMessageDialogComponent, {data: {head: 'Error', message: error.message}})
     .afterClosed().subscribe(value => {
     if (error.status_code === 401 || error.status_code === 403) {
     this.dataManager.logOut();
     } else {
     this.routeNavigate(this.errorRedirectUrl);
     }
     });*/
  }

  /**
   * to handle every components destroy
   */
  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  reditrect(id): string {
    return this.redirect_link + id;
  }

  /**
   * func to check for user permission on actions
   * @param permissionStr
   */
  permissionCheck(permissionStr: string): boolean {
    // console.log(
    //   'permission check',
    //   this.dataManager.permissionsArray.find(
    //     (permissionObj) => permissionObj.permission_name === permissionStr
    //   )
    //     ? true
    //     : false,
    //   this.dataManager.permissionsArray,
    //   permissionStr
    // );
    return this.dataManager.permissionsArray.find(
      (permissionObj) => permissionObj.permission_name === permissionStr
    )
      ? true
      : false;
  }

  /**
   *func to find difference in days
   * between 2 dates
   */
  dateDifference(start: Date, end: Date) {
    const diff = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  /**
   * Function for Masking Email and phone for security purpose.
   */
  maskEmail(data) {
    var arr = data.split('@');
    this.user_email = this.maskWord(arr[0]) + '@' + arr[1];
  }

  maskWord(str) {
    return str[0] + '_'.repeat(6);
  }

  maskPhone(data) {
    const x = data.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    this.user_mobile = !x[2] ? x[1] : '(' + '_'.repeat(3) + ') ' + ('_'.repeat(3)) + (x[3] ? '-' + x[3] : '');
    this.user_mobile = '+1' + this.user_mobile;
  }

  numericOnly(event) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === '-') // dash
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  SetUserData(value) {
    localStorage.setItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN, value.response_content.token_type
      + ' ' + value.response_content.access_token);
    localStorage.setItem(AppConstants.STORAGE_KEY_USER_MENU, JSON.stringify(value.menu));
    localStorage.setItem(AppConstants.STORAGE_KEY_FIRST_MENU, JSON.stringify(value.menu ? value.menu[0].name : value.menu));
    localStorage.setItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS, JSON.stringify(value.permissions));
    value.user.login_as_merchant = value.login_as_merchant;
    localStorage.setItem(AppConstants.STORAGE_KEY_USER, JSON.stringify(value.user));
    localStorage.setItem(AppConstants.STORAGE_KEY_USER_ID, JSON.stringify(value.user_id));
    localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(value.current_account_id));
    localStorage.setItem(AppConstants.STORAGE_KEY_USER_NAME, JSON.stringify(value.user.name));
    localStorage.setItem(AppConstants.STORAGE_KEY_USER_EMAIL, JSON.stringify(value.user.email));
  }

  /** To find Null or undefined
   * @param value
   * @function isNullOrUndefined()
   */
  isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  /** To find Null or not
   * @param value
   * @function isNull()
   */
  isNull(value) {
    return value == null;
  }

  scrollcontent(from, to) {
    if (this.contentscroll) {
      return;
    }
    this.contentscroll = 1;
    document.getElementById(to).scrollLeft =
      document.getElementById(from).scrollLeft;
    this.contentscroll = 0;
  }

  checkTablewidth() {
    const table = document.getElementById('table-width');
    if (table) {
      return 'width:' + table.offsetWidth + 'px';
    }
  }

  extractAddressComponents(address: Address) {
    const addressData: any = {
      country: '',
      state: '',
      city: '',
      zip: '',
      streetAddress: '',
      streetNumber: '',
      streetName: '',
    };

    for (const addr of address.address_components) {
      if (addr.types.includes('country')) {
        addressData.country = addr.short_name;
      }
      if (addr.types.includes('administrative_area_level_1')) {
        addressData.state = addr.long_name;
      }
      if (addr.types.includes('locality')) {
        addressData.city = addr.long_name;
      }
      if (addr.types.includes('postal_code')) {
        addressData.zip = addr.long_name;
      }
      if (addr.types.includes('street_number')) {
        addressData.streetNumber = addr.long_name;
      }
      if (addr.types.includes('route')) {
        addressData.streetName = addr.long_name;
      }
    }

    addressData.streetAddress = `${addressData.streetNumber} ${addressData.streetName}`.trim();

    return addressData;
  }
}
