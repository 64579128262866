import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportModel } from '../../data/model/ExportModel/export-model';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../constants/app-constants';
import { GetExportExcelAction } from './get-export-excel.action';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { AlertMessageDialogComponent } from '../../dialogs/alert-message-dialog/alert-message-dialog.component';

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.css']
})
export class ExportExcelComponent extends GetExportExcelAction implements OnInit {
  @Input() exportExcel: ExportModel[];
  @Input() filterUrl: string;
  @Input() searchData: string;
  @Input() serviceName: any;
  doneFlag: boolean;
  Url: string;
  tz: string;
  count: number;
  excel = 1;
  countFlag: boolean;
  localStorage: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings  = {};
  _getcustomerexcel: string;
  selectedItemList: any;
  filter: string;
  blob: Blob;
  dmonth: any;
  dyear: any;
  current_date = new Date();
  from_date: Date = new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1);
  to_date: Date = new Date();
  requiredField: boolean = false;
  showError: boolean = false;
  topError: string;
  topErrorMessage: string;
  select_date: any;
  min_date = new Date();
  reference_number: any;
  timePeriods = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
  '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30','11:00', '11:00', '11:30', '12:00',
  '12:30','13:00','13:30','14:00','14:30','15:00','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30',
  '20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30']


  constructor(private datePipe: DatePipe, dataManager: DataManagerService, router: Router, route: ActivatedRoute, showSnackBar: MatSnackBar, private dialog: MatDialog,) {
    super();
    this.dataManager = dataManager;
    this.router = router;
    this.snackBar = showSnackBar;
    this.route = route;
    this.dropdownSettings  = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'All Columns',
      unSelectAllText: 'Unselect All Columns',
    };
    this.min_date.setFullYear(new Date().getFullYear() - 1);
  }

  ngOnInit() {
    const date = new Date();
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone ; //Local timezone
    this.dyear = date.getFullYear();
    this.dmonth = date.getMonth() + 1;
    this.dmonth = ( this.dmonth  <  10 )  ? ( '0' + this.dmonth ) : this.dmonth ;
    this._getcustomerexcel = AppConstants.BASE_URL + this.serviceName.exportServiceName + '?limit=' + this.limit + '&page=1'  + (!this.isNullOrUndefined(this.serviceName.include) ? this.serviceName.include : '') +  '&excel=' + this.excel;

    this.exportExcel.forEach(data => {
      if (!Array.isArray(this.serviceName.include) && this.serviceName.include == 'transaction_date' || this.serviceName.include == 'last_updated_date' || this.serviceName.include == 'report_date') {
        data.fieldKey = this.serviceName.include;
      } else if (Array.isArray(this.serviceName.include)) {
        this.select_date = !this.isNullOrUndefined(this.serviceName.include[0].date) ? this.serviceName.include[0].date : console.log();
        this.reference_number = !this.isNullOrUndefined(this.serviceName.include[0].reference_number) ? this.serviceName.include[0].reference_number : this.serviceName.include[0]['batch_reference_number'];
        if (data.options && data.componentType == 'date' && !data.subOption && !this.isNullOrUndefined(this.serviceName.include[0].data)) {
          data.options = [];
          for (let x of this.serviceName.include[0].data) {
          data.options.push(x)
          }
        }
        if(data.options && data.componentType == 'date' && data.key === 'status' && !this.isNullOrUndefined(this.serviceName.include[0].data.status)) {
          data.options = [];
          for (let x of this.serviceName.include[0].data.status) {
            data.options.push(x)
            }
        }
        if(data.options && data.componentType == 'date' && data.subOption && !this.isNullOrUndefined(this.serviceName.include[0].data.transaction_date)) {
          data.options = [];
          for (let x of this.serviceName.include[0].data.transaction_date) {
            data.options.push(x)
            }
        }
        if(this.isNullOrUndefined(this.select_date) && data.key ==='select_date') {
          data.heading =  'File reference number';
          data.fieldKey=  'batch_number';
          data.key = 'auth_reference_number';
        }
        if(!this.isNullOrUndefined(this.select_date) && data.key ==='auth_reference_number') {
          data.heading =  'Authorization date';
          data.fieldKey=  'date';
          data.key = 'select_date';
        }
         this._getcustomerexcel = this._getcustomerexcel.replace(this.serviceName.include, '');
      }


    })
    if(this.filterUrl) {
      this.filterUrl = this.filterUrl.substring(1);
    }
    this.setExportData();
    this.filterSubscription = this.dataManager.filterUrl.subscribe(res => {
      if(!this.isNullOrUndefined(res)){
      this.filterUrl = res.substring(1);
     this.setExportData();
      }
    })

  }
  validateDate() {
    if(this.from_date > this.to_date) {
      this.showError = true;
      this.errorMsg = 'From date should not exceed '  + this.datePipe.transform(this.to_date, 'MM/dd/yyyy');
    } else {
      this.showError = false;
    }
  }

  setExportData() {
    const date = new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    this.exportExcel.forEach(data => {
      if (data.componentType == 'date') {
        switch (data.key) {
          case 'equals':
            data['value'] = months[(date.getMonth())] + ' ' + date.getDate();
            data.checked = true;
            this.exportData(data);
            break;
          case 'between':
            data['value'] = months[(date.getMonth())] + ' ' + new Date(date.getFullYear(), date.getMonth(), 1).getDate() + ' - ' + (months[(date.getMonth())] + ' ' + new Date().getDate());
            break;
          case 'previousdays':
            data['value'] = months[new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7).getMonth()] + ' ' + new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7).getDate() + ' - ' + months[new Date(date.getFullYear(), date.getMonth(), date.getDate() + -1).getMonth()] + ' ' + new Date(date.getFullYear(), date.getMonth(), date.getDate() -1).getDate();
            break;
          case 'previousmonths':
            data['value'] = months[(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).getMonth())] + ' ' + (new Date(date.getFullYear(), date.getMonth() - 1, 1).getDate()) + ' - ' + (months[(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).getMonth())] + ' ' + (new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0).getDate()));
            break;
            case 'custom':
            case 'dateRate':
            data['value'] = this.from_date;
            break;
          case 'month' :
            data['value'] = months[(date.getMonth())];
            data.checked = true;
            this.exportData(data);
            break;
          case 'year' :
            data['value'] = date.getFullYear();
            break;
          case 'select_date':
            data.checked = true;
            data['value'] = !this.isNullOrUndefined(this.select_date) ? this.datePipe.transform(this.select_date, 'MM/dd/yyyy') : null;
            this.exportData(data);
            break;
          case 'auth_reference_number':
            data.checked = true;
            data['value'] = !this.isNullOrUndefined(this.reference_number) ? this.reference_number : null;
            this.exportData(data);
            break;
        }
      } else if (data.componentType === 'list') {
        this.dropdownList = data.options;
        this.selectedItems = data.options;
      }
      else if(data.componentType === 'general') {
        switch (data.key) {
          case 'general':
            data.checked = true;
            this.exportGeneralData(data);
            break;
        }
      }
    });
    console.log(this.exportExcel);
  }

  exportData(data) {
    this.Url = '&tz=' + this.tz;
    if (data) {
      console.log(data);
      this.exportExcel.forEach(value => {
        if (value.componentType == 'date') {
          if (value.heading === data.heading) {
            value.checked = true;
          } else {
            value.checked = false;
            value.date  = value.toDateTime = value.fromDateTime = value.fromTime = value.toTime  = null;
            value.selectedOption = null;
          }
        }
      })
      let count = 0;
      this.doneFlag = true;
      for (const fil of this.exportExcel) {
        fil.checked === true ? count++ : console.log(fil.checked);
        this.Url = this.Url + (fil.checked ? (this.createUrl(fil) !== '' ? ( '&'+ this.createUrl(fil)) : '') : '');
        console.log('Url', this.Url);
      }
      this.count = count;
      this.countFlag = this.count > 0 ? true : false;
    }
  }

  createUrl(arg: ExportModel) {
    const date = new Date()
    switch (arg.componentType) {
      case 'date':
        if (arg.heading === 'Today') {
          return (arg['value'] ? (arg.fieldKey + '[eq]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'yyyy/MM/dd')) : '');
        }
        if (arg.heading === 'Current month') {
          return ((arg['value']) ? arg.fieldKey + '[gte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), (new Date(date.getFullYear(), date.getMonth(), 1).getDate())), 'yyyy/MM/dd')
            + '&' + arg.fieldKey + '[lte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'yyyy/MM/dd') : '');
        }
        if (arg.heading === 'Previous 7 days') {
          return ((arg['value']) ? arg.fieldKey + '[gte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), (new Date().getDate() - 7)), 'yyyy/MM/dd')
            + '&' + arg.fieldKey + '[lte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), (new Date().getDate() - 1)), 'yyyy/MM/dd') : '');
        }
        if (arg.heading === 'Previous month') {
          return ((arg['value']) ? arg.fieldKey + '[gte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() - 1, (new Date(date.getFullYear(), date.getMonth() - 1, 1).getDate())), 'yyyy/MM/dd')
            + '&' + arg.fieldKey + '[lte]=' + this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() - 1, (new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0).getDate())), 'yyyy/MM/dd') : '');
        }
        if (arg.heading === 'Custom') {
          return ((arg['value']) ? arg.fieldKey + '[gte]=' + this.datePipe.transform(this.from_date, 'yyyy/MM/dd')
            + '&' + arg.fieldKey + '[lte]=' + this.datePipe.transform(this.to_date, 'yyyy/MM/dd') : '');
        }
        if( arg.heading === 'Date range') {
          return ((arg['value']) ? (arg.dateFrom == null ? arg.fieldKey + '[gte]='+ this.datePipe.transform(this.from_date, 'yyyy/MM/dd') :  arg.dateFrom + '='+ this.datePipe.transform(this.from_date, 'yyyy-MM-dd'))
            + '&' + (arg.dateTo == null ? arg.fieldKey + '[lte]=' + this.datePipe.transform(this.to_date, 'yyyy/MM/dd') :  arg.dateTo + '='+ this.datePipe.transform(this.to_date, 'yyyy-MM-dd')): '');
        }
        if (arg.heading === 'Month' || arg.heading === 'Year') {
          let fromData = (arg['value']) ? arg.dateFrom != null ? 'from_date=' + this.datePipe.transform(new Date(this.dyear, Number(this.dmonth)-1, new Date(this.dyear, this.dmonth, 1).getDate()), 'yyyy-MM-dd') : arg.fieldKey + '[gte]=' + this.datePipe.transform(new Date(this.dyear, Number(this.dmonth)-1, new Date(this.dyear, this.dmonth, 1).getDate()), 'yyyy/MM/dd') : '';
          let toData = (arg['value']) ? arg.dateTo != null ? 'to_date=' + this.datePipe.transform(new Date(this.dyear, Number(this.dmonth)-1, new Date(this.dyear, Number(this.dmonth) - 1 + 1, 0).getDate()), 'yyyy-MM-dd') : (arg.fieldKey + '[lte]=' + this.datePipe.transform(new Date(this.dyear, Number(this.dmonth)-1, new Date(this.dyear, Number(this.dmonth) - 1 +1, 0).getDate()), 'yyyy/MM/dd')) : '';
          return fromData + '&' + toData
        }
        if (arg.heading === 'Batch reference number') {
          return (!this.isNullOrUndefined(this.select_date) ? arg.fieldKey + '=' + this.datePipe.transform(this.select_date, 'yyyy-MM-dd'): '') + '&reference_number=' + arg.selectedOption;
        }
        if (arg.heading === 'Batch date' || arg.heading === 'Authorization date') {
          return (!this.isNullOrUndefined(this.select_date) ? arg.fieldKey + '='  + this.datePipe.transform(this.select_date, 'yyyy-MM-dd'): '');
        }
        if (arg.heading === 'File reference number') {
          return (!this.isNullOrUndefined(this.reference_number) ? '&batch_number=' + this.reference_number : '');
        }
        if (arg.heading === 'Time' || arg.heading === 'Transaction date') {
          return ((arg.key === 'time') ? arg.fieldKey + '=' + this.datePipe.transform(this.select_date, 'yyyy-MM-dd') + (!this.isNullOrUndefined(arg.fromTime) ? '&from_time=' + arg.fromTime: '') + (!this.isNullOrUndefined(arg.toTime) ? '&to_time=' + arg.toTime: '') :
          ((!this.isNullOrUndefined(this.select_date) && arg.key != 'time') ? 'sortedBy=desc'+  '&date=' + this.datePipe.transform(this.select_date, 'yyyy-MM-dd'): '&batch_number=' + this.reference_number) + '&' + arg.fieldKey.replace('&', '%26') + '[gte]=' + this.datePipe.transform(arg.fromDateTime, 'yyyy-MM-dd') + ' ' +(!this.isNullOrUndefined(arg.fromTime) ? arg.fromTime: '') + '&' + arg.fieldKey.replace('&', '%26')+ '[lte]=' + this.datePipe.transform(arg.toDateTime, 'yyyy-MM-dd') + ' ' +(!this.isNullOrUndefined(arg.toTime) ? arg.toTime: ''));
        }
        if (arg.heading === 'Status') {
          this.showError = false;
          return ('sortedBy=desc'+ (!this.isNullOrUndefined(this.select_date) ? '&date=' + this.datePipe.transform(this.select_date, 'yyyy-MM-dd'): '&batch_number=' + this.reference_number) + '&' + arg.fieldKey +'=' + arg.selectedOption) ;
        }
        if (arg.heading === 'All') {
          if(this.serviceName.include && (this.select_date || this.reference_number) ) {
            arg.fromTime = null; arg.toTime = null; this.showError = false;
            return ('sortedBy=desc&'+ (!this.isNullOrUndefined(this.select_date) ? arg.fieldKey + '=' + this.datePipe.transform(this.select_date, 'yyyy-MM-dd'): '&batch_number=' + this.reference_number )) ;
          } else {
          return '';
          }
        }
        break;
      case 'general':
        if (arg.heading === 'Applied filter') {
          if(this.filterUrl) {

          return !this.isNullOrUndefined(this.filterUrl) ? this.filterUrl : '';
          } else {
            return '';
          }
        }
        if (arg.heading === 'All') {
          return '';
        }
        if (arg.heading === 'Search') {
          return (!this.isNullOrUndefined(this.searchData) ? ('&search=' + this.searchData) : '');;
        }
        break;
    }
  }
  getExportExcel() {
    this.loadFlag = true;
    let selected_colums = '';
    for (let i = 0; i < this.selectedItems.length; i++) {
      selected_colums += this.selectedItems[i].key + (i != (this.selectedItems.length - 1) ? ',' : '');
    }
    this._getExportexcel = this._getcustomerexcel + this.Url.replace('&&', '&') + ('&selected_columns=' + selected_colums);
    this.getExportlogexcel(this._getExportexcel);
  }
  export() {
    this.exportExcel.forEach(data => {
      if((data.componentType == 'date' && data.checked && !data.required)) {
        this.showError = false;
        this.getExportExcel();
      }
      else if (data.componentType == 'date' && data.checked && data.required) {
        if(!this.isNullOrUndefined(data.fromDateTime) && !this.isNullOrUndefined(data.fromTime) && !this.isNullOrUndefined(data.toDateTime) && !this.isNullOrUndefined(data.toTime) || !this.isNullOrUndefined(data.selectedOption)) {
          this.showError = false;
          this.getExportExcel();
        }
          else if(!this.isNullOrUndefined(data.fromTime) && !this.isNullOrUndefined(data.toTime)) {
            this.showError = false;
            this.getExportExcel();
        } else {
          this.showError = true;
        }
      }
    })
  }

  cancelBtn() {
    if(this.serviceName.cancelRedirectUrl) {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {

        this.router.navigate([this.serviceName.cancelRedirectUrl], { relativeTo: this.route });
      });
    }
    else if (this.select_date || this.reference_number) {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.serviceName.redirectUrl], { relativeTo: this.route, queryParams: { reference_number: this.reference_number, date: this.select_date } });
      });
    }
    else {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.serviceName.redirectUrl], { relativeTo: this.route });
      });
      // window.location.assign('http://192.168.9.110:4200/customer/customer-list');
    }


  }
  onItemSelect(item: any) {
    console.log(item)

  }
  onDeSelect(item: any) {
    console.log(item)
  }
  onFilterChange(item: any) {
    console.log(item)
  }

  exportGeneralData(data) {
    this.Url = '&tz=' + this.tz;
    if (data) {
      this.exportExcel.forEach(value => {
        if (value.componentType == 'general') {
          if (value.heading === data.heading) {
            if(data.heading === 'Applied filter') {
              this.filterModel();
            }
            if(data.heading === 'Search') {
              this.searchModel();
            }
            value.checked = true;
          } else {
            value.checked = false;
          }
        }

      })
      let count = 0;
      this.doneFlag = true;
      for (const fil of this.exportExcel) {
        fil.checked === true ? count++ : console.log(fil.checked);
        this.Url = this.Url + (fil.checked ? (this.createUrl(fil) !== '' ? ('&' + this.createUrl(fil)) : '') : '');
        console.log('Url', this.Url);
      }
      this.count = count;
      this.countFlag = this.count > 0 ? true : false;
    }
  }

  passExportExcelResponse(res) {
    if(res['body'].type === 'application/json') {
      this.topErrorMessage = 'No records found.'
      document.getElementsByClassName('page-container')[0].scrollTop = 0;
    } else {
      if(this.serviceName.fileName != '') {
    this.showSnackBar('File is loading.', 'Ok', 3000);
    this.loadFlag = false;



      this.blob = new Blob([res['body']], { type: 'application/vnd.ms-excel' });
      var downloadURL = window.URL.createObjectURL(res['body']);
      var link = document.createElement('a');
      link.href = downloadURL;
      let responseFileName = res.headers.get('content-disposition').split('=')[1];
      link.download = responseFileName;

      link.click();
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        if (isMobileDevice) {
          setTimeout(() =>{
            this.cancelBtn();
          },3000);
        } else {
          this.cancelBtn();
        }

        } else {
          const data = {
                head: 'Alert', message: 'The file is being prepared. Kindly check after few minutes for download.'
              };
            const dialog = this.dialog.open(MessageDialogComponent, {data});
            dialog.afterClosed().subscribe(value => {
            console.log(value);
            if (value) {

              this.loadFlag = true;

              this.routeNavigate(this.serviceName.redirectUrl);
            }
          });
        }
    }

  }
  protected passExportExcelError(err: any) {
      console.log(err)
  }
  onChange(data) {
    this.exportExcel.find(value => {
      if(value.heading != data.heading) {
       return this.showError = false;
      }
    })
    this.exportData(data);
  }
  setCustomdate(selecedItem) {
    if (!this.isNullOrUndefined(this.from_date) && this.from_date.toString() != 'Invalid Date') {
      this.exportData(selecedItem)
    }
  }
  setCustomdate2(selectedObj) {
    if (!this.isNullOrUndefined(this.to_date) && this.to_date.toString() != 'Invalid Date') {
      this.exportData(selectedObj)
    }

  }
  protected getListErr(err: any) {

  }
  protected getListRes(res: any) {

  }
  setStatus() {
    (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }
  setClass() {
    this.setStatus();
    var dropdown = document.getElementsByClassName('dropdown-btn');
    if (this.selectedItems.length > 0) {
      for(let i = 0; i < dropdown.length; i++) {
        dropdown[i]['style'].borderColor = '#adadad';
      }
     }
    else {
      for(let i = 0; i < dropdown.length; i++) {
        dropdown[i]['style'].borderColor = '#a94442';
      }
    }
  }
  filterModel() {
    if(!this.isNullOrUndefined(this.filterUrl) && (this.filterUrl.includes('created_at') || this.filterUrl.includes('arrival_date') || this.filterUrl.includes('Transaction_Date_and_Time'))) {
    const data = {
      head: 'Alert', message: 'Date range is already selected in the filter. Do you want to continue?', confirmBtn: 'Yes', cancelBtn: 'No'
    };
    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      if(value != true) {
        this.setDefaultOption();
      } else {
        this.Url = '&'+ this.filterUrl;
      }
    });
  } else if(this.isNullOrUndefined(this.filterUrl) || this.filterUrl === '') {
      const data = {
        head: 'Alert', message: 'No filter is selected from the list.'
      };
      const dialog = this.dialog.open(AlertMessageDialogComponent, { data });
      dialog.afterClosed().subscribe(value => {
        if (value) {
          this.setDefaultOption();
        }
      })
    }
  }
  searchModel() {
    if(this.isNullOrUndefined(this.searchData)) {
      const data = {
        head: 'Alert', message: 'No value applied in search.'
      };
      const dialog = this.dialog.open(AlertMessageDialogComponent, { data });
      dialog.afterClosed().subscribe(value => {
        if (value) {
          this.setDefaultOption();
        }
      })
    }
  }
  checkTime(data) {
    if(!this.isNullOrUndefined(data.fromTime) &&!this.isNullOrUndefined(data.toTime) ) {
    if((new Date(this.select_date + ' ' + data.fromTime).getTime() /1000) > (new Date(this.select_date + ' ' + data.toTime).getTime() /1000)) {
      this.showError = true;
    }
    else {
      this.showError = false
    }
  }
    this.exportData(data);
  }
  setDefaultOption() {
    this.exportExcel.forEach(data => {
      if(data.componentType === 'general') {
        switch (data.key) {
          case 'general':
            data.checked = true;
            this.exportGeneralData(data);
            break;
        }
      }
    })
  }
  checkDateTime(data) {
    if(!this.isNullOrUndefined(data.fromDateTime) && !this.isNullOrUndefined(data.fromTime) && !this.isNullOrUndefined(data.toDateTime) && !this.isNullOrUndefined(data.toTime) ) {
      if((new Date(data.fromDateTime + ' ' + data.fromTime).getTime()/1000) > (new Date(data.toDateTime + ' ' + data.toTime).getTime()/1000)) {
        this.showError = true;
      } else {
        this.showError = false;
      }
      this.exportData(data);

    }
  }
}
