import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataManagerService} from '../data/services/DataManager/data-manager.service';
import {AiReportsActionDirective} from './ai-reports.action';
import {AppConstants} from '../shared/constants/app-constants';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-ai-reports',
  templateUrl: './ai-reports.component.html',
  styleUrls: ['./ai-reports.component.css']
})
export class AiReportsComponent extends AiReportsActionDirective implements OnInit {
  _router: Router;
  externalUrl: any;

  constructor(router: Router, private _sanitizer: DomSanitizer, dataManager: DataManagerService) {
    super();

    this._router = router;
    this.router = router;
    this.dataManager = dataManager;
    this._apiGetUrl = AppConstants.BASE_URL + '/get-merchant-dashboard-q-bar';
  }

  ngOnInit(): void {
    this.loadFlag = true;
    this.getPageData();
  }

  protected passPageData(response: any) {
    this.loadFlag = false;
    this.externalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(response);
  }

  protected passApiError(error: any) {
    this.loadFlag = false;
    console.log('error', error);
  }

}
