<ng-container *ngIf="loadFlag">
  <table>
    <tr class="loader">
      <td colspan="7">
        <div class="loader-container">
          <img class="table-loader" src="../../../../assets/images/loading.svg">
        </div>
      </td>
    </tr>
  </table>
</ng-container>

<div class="container-fluid" *ngIf="!loadFlag">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <h3 class="headings">AI Reports</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3" *ngIf="externalUrl !== ''">
      <iframe [src]="externalUrl" width="100%" height="100%"></iframe>
    </div>
  </div>
</div>
