import {Component, Inject, OnInit} from '@angular/core';
import {SettingsAction} from './settings.action';
import {AppConstants} from '../../../shared/constants/app-constants';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends SettingsAction implements OnInit {
  paypalLogoUrl = AppConstants.PAYPAL_LOGO_URL;
  venmoLogoUrl = AppConstants.VENMO_LOGO_URL;
  payLaterLogoUrl = AppConstants.PAYLATER_LOGO_URL;
  showPaypal = AppConstants.SHOW_PAYPAL;

  constructor(dataManager: DataManagerService, @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.dataManager = dataManager;
    this._getSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS + '?limit=200';
    // '/account-settings/?limit=500'; // + AppConstants.SERVICE_NAME_ACCOUNT_SETTING  + AppConstants.SERVICE_NAME_TOKENIZE;
    this._setSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS;
    // AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_TOKENIZE;
    this.getSetting();
  }

  ngOnInit() {
  }

  setTokenize() {
    const postBody = {};
    postBody['id'] = this.settings.tokenize_id;
    postBody['tokenize'] = this.settings.tokenize ? 1 : 0;
    this.setSetting(postBody);
  }

  setMetadata() {
    const postBody = {};
    postBody['id'] = this.settings.display_transactional_metadata_id;
    postBody['display_transactional_metadata'] = this.settings.display_transactional_metadata ? 1 : 0;
    this.setSetting(postBody);
  }

  setChargeEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.disable_charge_notification_email_id;
    postBody['disable_charge_notification_email'] = this.settings.disable_charge_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }
  setChargebackEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_chargeback_notification_email_id;
    postBody['enable_chargeback_notification_email'] = this.settings.enable_chargeback_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }
  setChargeSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.disable_charge_notification_sms_id;
    postBody['disable_charge_notification_sms'] = this.settings.disable_charge_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }

  setEnablePaypal() {
    const postBody = {};
    postBody['id'] = this.settings.enable_paypal_payments_id;
    postBody['enable_paypal_payments'] = this.settings.enable_paypal_payments ? 1 : 0;
    this.setSetting(postBody);
    if (!this.settings.enable_paypal_payments && this.settings.enable_paypal_paylater) {
      this.settings.enable_paypal_paylater = false;
      this.setEnablePaypalPaylater(true);
    }
    if (!this.settings.enable_paypal_payments && this.settings.enable_paypal_venmo) {
      this.settings.enable_paypal_venmo = false;
      this.setEnablePaypalVenmo(true);
    }
  }

  setEnablePaypalPaylater(reverse = false) {
    const postBody = {};
    postBody['id'] = this.settings.enable_paypal_paylater_id;
    if (reverse) {
      postBody['enable_paypal_paylater'] = this.settings.enable_paypal_paylater ? 0 : 1;
    } else {
      postBody['enable_paypal_paylater'] = this.settings.enable_paypal_paylater ? 1 : 0;
    }
    this.setSetting(postBody);
  }

  setEnablePaypalVenmo(reverse = false) {
    const postBody = {};
    postBody['id'] = this.settings.enable_paypal_venmo_id;
    if (reverse) {
      postBody['enable_paypal_venmo'] = this.settings.enable_paypal_venmo ? 0 : 1;
    } else {
      postBody['enable_paypal_venmo'] = this.settings.enable_paypal_venmo ? 1 : 0;
    }
    this.setSetting(postBody);
  }

  setChargebackSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_chargeback_notification_sms_id;
    postBody['enable_chargeback_notification_sms'] = this.settings.enable_chargeback_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }

  setDepositEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_deposit_notification_email_id;
    postBody['enable_deposit_notification_email'] = this.settings.enable_deposit_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }

  setDepositSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_deposit_notification_sms_id;
    postBody['enable_deposit_notification_sms'] = this.settings.enable_deposit_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }

  protected passSetSettingError(err) {
  }

  protected passSetSettingResponse(res) {
  }
  protected passGetSettingError(error) {

  }

  enablePaypal(forceNew = false) {
    this.loadFlag = true;
    const accountId = this.localStorage.current_account_id.replace(/^"|"$/g, '');
    const payload = {
      account_id: accountId,
      force_new: forceNew
    };
    this.dataManager.postRequest(AppConstants.BASE_URL + '/account/setup_paypal', payload, 0)
      .subscribe(
        (response) => {
          if (response.data && response.data.boarding_link) {
            window.open(response.data.boarding_link, '_blank');
          } else {
            console.log('No boarding link found in the response.');
          }
          this.loadFlag = false;
        },
        (error) => {
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
        }
      );
  }
}
