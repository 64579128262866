<div class="container-fluid mt-5 b_shadow">
    <div class="row">
      <span class="col-sm-12 col-12 col-md-12 col-lg-12 create-header header bg-infos">
        Add New Bank Account
      </span>
    </div>
   <ng-container *ngIf="topErrorMessage">
      <div class="row err" *ngFor="let obj of keys1 ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-12 mb-1" *ngFor="let value of obj ">
          <p class="col-md-12 col-lg-12 col-sm-12 col-12 error" id="err">{{value}}</p>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="!topErrorMessage">
      <div class="col-md-12 col-lg-12 col-sm-12 col-12 error"> {{errorMsg}}</div>
    </div>
    <div class="col-lg-9 col-sm-12 col-12 col-md-12 col-xl-8 pt-4">
       <form [formGroup]="createBankAccountForm" #createBankAccountF='ngForm'  (ngSubmit)="createBankAccountForm.invalid ? print(createBankAccountForm) : createBankAccount()">
        <div class="col-md-12">

          <div class="form-group row align-items-center">
            <label for="account_number" class="col-sm-5 col-12 col-form-label mb-1">Account Number:<i class="required-icon">*</i></label>
            <span class="error_sign" id="account_number1">!</span>
            <div class="col-sm-7 col-12">
              <input class="form-control" value="" name="account_number" id="account_number" [class.invalid]="createBankAccountForm.controls['account_number'].invalid && createBankAccountF.submitted"
              type="number" placeholder="••••••••••••••••"
                     formControlName="account_number"
                     maxlength="17" (change)="setAchDetails($event)" (keyup)="enabledinpute();"    (keypress)="_keyPress($event)" >
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['account_number'].errors?.required">Account Number is required.</span>
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && !createBankAccountForm.controls['account_number'].errors?.required && createBankAccountForm.controls['account_number'].invalid">Account Number is invalid.Must be max of 17 numbers and min 3.</span>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="routing_number" class="col-sm-5 col-12 col-form-label mb-1">Routing Number:<i class="required-icon">*</i></label>
            <span class="error_sign" id="routing_number_sign">!</span>
            <div class="col-sm-7 col-12">
              <input class="form-control" value="" name="routing_number" id="routing_number" [class.invalid]="createBankAccountForm.controls['routing_number'].invalid && createBankAccountF.submitted"  type="number" placeholder="•••••••••••••"
              formControlName="routing_number"
               (change)="setAchDetails($event)" (keyup)="enabledinpute();"    (keypress)="_keyPress($event)" >
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['routing_number'].errors?.required">Routing Number is required.</span>
              <span class="validation-err-msg" *ngIf="createBankAccountForm.controls['routing_number'].errors?.pattern">Routing Number is invalid.Must me max of 9 numbers.</span>
            </div>
          </div>
          <div class="form-group row align-items-center mt-sm-0 mt-4">
            <label for="first_name" class="col-sm-5 col-12 col-form-label">First Name:<i class="required-icon">*</i></label>
            <span class="error_sign" id="first_name">!</span>
            <div class="col-sm-7 col-12">
              <input class="form-control" name="first_name"  formControlName="first_name"
                      type="text"
                      value="" maxlength="200" [class.invalid]="createBankAccountForm.controls['first_name'].invalid && createBankAccountF.submitted"
                      id="first_name" (change)="setAchDetails($event)" />
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['first_name'].errors?.required">Account First Name is required.</span>
              <span class="validation-err-msg" *ngIf=" createBankAccountForm.controls['first_name'].errors?.maxLength">Account First Name limit exceed.</span>
            </div>
          </div>
          <div class="form-group row align-items-center mt-sm-0 mt-4">
            <label for="last_name" class="col-sm-5 col-12 col-form-label">Last Name:<i class="required-icon">*</i></label>
            <span class="error_sign" id="name1">!</span>
            <div class="col-sm-7 col-12">
              <input class="form-control" name="last_name" formControlName="last_name"
                      type="text"
                      value="" maxlength="200" [class.invalid]="createBankAccountForm.controls['last_name'].invalid && createBankAccountF.submitted"
                      id="last_name" (change)="setAchDetails($event)"/>
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['last_name'].errors?.required">Account Last Name is required.</span>
              <span class="validation-err-msg" *ngIf="createBankAccountForm.controls['last_name'].errors?.maxLength">Account Last Name limit exceed.</span>
            </div>
          </div>
          <div class="form-group row align-items-center mt-sm-0 mt-4">
            <label class="col-xl-5 col-lg-5 col-md-3 col-sm-3 col-12  pt-2">Account Type: <i class="required-icon">*</i></label>
            <div class="col-sm-7 col-12">
              <select class="form-control"  formControlName="account_type" id="account_type" (change)="setAchDetails($event)">
                <option value="">Select Option</option>
                <option *ngFor="let category of categories">{{category}}</option>
              </select>
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['account_type'].errors?.required">Account Type is required.</span>
              <span class="validation-err-msg" *ngIf="createBankAccountForm.controls['account_type'].errors?.maxLength">Account Type limit exceed.</span>
            </div>
          </div>

          <div *ngIf="createBankAccountForm.get('account_type').value === 'Business Checking' || createBankAccountForm.get('account_type').value === 'Business Savings'" class="form-group row align-items-center mt-sm-0 mt-4">
            <label for="company_name" class="col-sm-5 col-12 col-form-label">Company Name: <i class="required-icon">*</i></label>
            <span class="error_sign" id="nameCompany">!</span>
            <div class="col-sm-7 col-12">
              <input class="form-control" name="company_name" formControlName="company_name"
                     type="text"
                     value="" maxlength="50" [class.invalid]="createBankAccountForm.controls['company_name']?.invalid && createBankAccountF.submitted"
                     id="company_name" (change)="setAchDetails($event)"/>
              <span class="validation-err-msg" *ngIf="createBankAccountF.submitted && createBankAccountForm.controls['company_name']?.errors?.required">Company Name is required for selected account type.</span>
              <span class="validation-err-msg" *ngIf="createBankAccountForm.controls['company_name']?.errors?.maxLength">Company Name limit exceed.</span>
            </div>
          </div>


        </div>


           <div class="row justify-content-end px-4 py-4">
             <button class="update_cancel" (click)="cancelBankAccount()">Cancel</button>
             <app-loading-button   [loadFlag]="loadFlag"  class="float-right" [buttonText]="'Add Bank Account'" type="submit">
            </app-loading-button>

           </div>
       </form>
    </div>

